import store from '../Redux/store'

export default class FasterApi {
    // static baseUrl = 'http://faster.dev.etherial.fr'
    // static baseUrl = 'https://api.faster-sn.com'
    // static baseUrl = 'http://192.168.1.230:7000'

    static baseUrl = 'https://jo0os44ws4w88k8sggsc4w8w.etherial.dev'

    static isLogged() {
        return FasterApi.getToken() != null
    }

    static getToken() {
        return localStorage.getItem('token')
    }

    static setToken(token) {
        return localStorage.setItem('token', token)
    }

    static me() {
        return JSON.parse(localStorage.getItem('me'))
    }

    static setMe(me) {
        return localStorage.setItem('me', JSON.stringify(me))
    }

    static handleErrors(errors) {
        let errorMsgs = ''
        errors.map((e) => {
            errorMsgs = errorMsgs + '\n' + e.msg
        })
        return errorMsgs
    }

    static unauth() {
        return new Promise((resolve) => {
            localStorage.removeItem('token')
            localStorage.removeItem('me')
            resolve()
        })
    }

    static objToQueryString(obj) {
        const keyValuePairs = []
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
        }
        return keyValuePairs.join('&')
    }

    static call(method, path, body) {
        return new Promise((resolve, reject) => {
            let options = {
                method: method,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }

            if (body) {
                options.body = JSON.stringify(body)
            }

            fetch(this.baseUrl + path, options).then((response) => {
                response.json().then((res) => {
                    if (res.status >= 200 && res.status <= 299) {
                        resolve(res)
                    } else {
                        resolve({ ...res, errors: FasterApi.handleErrors(res.errors) })
                    }
                })
            })
        })
    }

    static createPhoneNumber(phoneNumber) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/users/me/phone`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify(phoneNumber),
            }).then((response) => {
                resolve(response.json())
            })
        })
    }
    static async ValidatePhoneNumber(code) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/users/me/phone/validate`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify(code),
            })
                .then((response) => {
                    resolve(response.json())
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }

    static auth({ email, password }) {
        return new Promise((resolve, reject) => {
            fetch(this.baseUrl + '/auth', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        FasterApi.setToken(res.data.token)
                        resolve({ status: 'OK', data: res.data.token })
                    } else {
                        resolve({ status: 'ERROR', errors: FasterApi.handleErrors(res.errors) })
                    }
                })
            })
        })
    }

    static signup(user) {
        console.log('user: ', user)
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/users`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(user),
            })
                .then((response) => {
                    response.json().then((res) => {
                        console.log('res: ', res)
                        resolve(res)
                        if (res.status === 201) {
                            this.auth(user)
                        }
                    })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    static getMe() {
        return new Promise((resolve, reject) => {
            fetch(this.baseUrl + '/users/me', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                        store.dispatch({
                            type: 'SET_USER',
                            payload: res.data,
                        })
                    } else {
                        // console.log(reject)
                        resolve(res)
                    }
                })
            })
        })
    }

    static getCompany({ company_id }) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/companies/${company_id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static putCompany({ company_id }, data) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/companies/${company_id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify(data),
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getTransactions(routing, qs) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/transactions${FasterApi.qsGenerator(qs)}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        console.log(res)
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getTransaction({ transaction_id }) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/transactions/${transaction_id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getBookings(routing, qs) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/bookings${FasterApi.qsGenerator(qs)}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getBooking({ booking_id }) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/bookings/${booking_id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static updateDriverStatus(id, data) {
        return new Promise((resolve, reject) => {
            fetch(FasterApi.baseUrl + `/drivers/${id}/status`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify(data),
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static deleteDriverDocument(id, data) {
        return new Promise((resolve, reject) => {
            fetch(FasterApi.baseUrl + `/drivers/${id}/documents`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify(data),
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getSignedUrlForGet(params) {
        return new Promise((resolve, reject) => {
            fetch(FasterApi.baseUrl + `/s3/file?${FasterApi.objToQueryString(params)}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getSignedUrlForPost(params) {
        return new Promise((resolve, reject) => {
            fetch(FasterApi.baseUrl + `/s3/sign?${FasterApi.objToQueryString(params)}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static postDriverDocument(id, params) {
        return new Promise((resolve, reject) => {
            fetch(FasterApi.baseUrl + `/drivers/${id}/documents`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify(params),
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getUsers(routing, qs) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/users${FasterApi.qsGenerator(qs)}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    console.log('userrr: ', res)
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getClaims(routing, qs) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/claims${FasterApi.qsGenerator(qs)}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    console.log('getClaims: ', res)

                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }
    static getTrips(routing, qs) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/trips${FasterApi.qsGenerator(qs)}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getMyTrips() {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/users/me/trips?as=driver&canceled=false&company=false`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            })
                .then((response) => {
                    response.json().then((res) => {
                        console.log('trips: ', res)
                        resolve(res)
                    })
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }

    static createClaimMessage(id, message) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/claims/${id}/messages`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify(message),
            }).then((response) => {
                response.json().then((res) => {
                    console.log('createClaimMessage: ', res)

                    if (res.status === 201 || res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static closeClaim(id) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/claims/${id}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    console.log('closeClaimMessage: ', res)

                    if (res.status === 201 || res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getDrivers(routing, qs) {
        return new Promise((resolve, reject) => {
            console.log(FasterApi.qsGenerator(qs))

            fetch(`${FasterApi.baseUrl}/drivers${FasterApi.qsGenerator(qs)}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getCompanyStadiums({ company_id }, qs) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/companies/${company_id}/stadiums${FasterApi.qsGenerator(qs)}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getStadium({ stadium_id }) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/stadiums/${stadium_id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static putStadium({ stadium_id }, { status, name, comment, price }) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/stadiums/${stadium_id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify({
                    status,
                    name,
                    comment,
                    price,
                }),
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static putStadiumPictures({ company_id }, { picture }) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/copanies/${company_id}/picture`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify({
                    picture,
                }),
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static putStadiumPictures({ stadium_id }, { pictures }) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/stadiums/${stadium_id}/pictures`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify({
                    pictures,
                }),
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }
    static getMyDriverRates(id) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/drivers/${id}/trips/rates`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            })
                .then((response) => {
                    response.json().then((res) => {
                        console.log('getMyDriverRates: ', res)

                        resolve(res)
                    })
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }

    static getVehicles(id) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/drivers/${id}/vehicles`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    console.log('getVehicles: ', res)
                    if (res.status === 200) {
                        resolve(res.data)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getMyDriverSummary(id, date) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/drivers/${id}/summary?date=${date}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            })
                .then((response) => {
                    response.json().then((res) => {
                        console.log('getMyDriverSummary: ', res)

                        resolve(res)
                    })
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }

    static getMyDriverActivitySummary(id, date) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/drivers/${id}/activities/summary?date=${date}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            })
                .then((response) => {
                    response.json().then((res) => {
                        console.log('getMyDriverActivitySummary: ', res)
                        resolve(res)
                    })
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }

    static getUserRates(id) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/users/${id}/trips/rates`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            })
                .then((response) => {
                    response.json().then((res) => {
                        console.log('getUserRates: ', res)

                        resolve(res)
                    })
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }
    static getCompanyStadiumsAvailabilities({ company_id }, { date }) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/companies/${company_id}/stadiums/availabilities?date=${date}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getCompanyAvailabilities({ company_id }) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/companies/${company_id}/availabilities`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static putCompanyAvailabilities({ company_id }, data) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/companies/${company_id}/availabilities`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify({ data: data }),
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static createCompany(data) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/companies`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify(data),
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 201) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static createStadium(data) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/stadiums`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
                body: JSON.stringify(data),
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 201) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static deleteStadium({ stadium_id }) {
        return FasterApi.call('DELETE', `/stadiums/${stadium_id}`)
    }

    static getCompanyBankInformation({ company_id }) {
        return FasterApi.call('GET', `/companies/${company_id}/bankinformation`)
    }

    static putCompanyBankInformation({ company_id }, data) {
        return FasterApi.call('PUT', `/companies/${company_id}/bankinformation`, data)
    }

    static putBooking({ booking_id }, data) {
        return FasterApi.call('PUT', `/bookings/${booking_id}`, data)
    }

    static putBookingUser({ booking_user_id }, data) {
        return FasterApi.call('PUT', `/bookings/users/${booking_user_id}`, data)
    }

    static getDriver({ driver_id }) {
        return FasterApi.call('GET', `/drivers/${driver_id}`)
    }

    static getUser({ user_id }) {
        return FasterApi.call('GET', `/users/${user_id}`)
    }

    static getClaim({ claim_id }) {
        return FasterApi.call('GET', `/claims/${claim_id}`)
    }
    static getTrip({ trip_id }) {
        return FasterApi.call('GET', `/trips/${trip_id}`)
    }

    static getDriverWidthdraws({ driver_id }) {
        return FasterApi.call('GET', `/drivers/${driver_id}/withdraws`)
    }

    static updateMyAccount(data) {
        return FasterApi.call('PUT', `/users/me`, data)
    }

    static postDriverBankaccount(driver_id, data) {
        return FasterApi.call('POST', `/drivers/${driver_id}/bankaccount`, data)
    }

    static postDriver(driver_id, data) {
        return FasterApi.call('POST', `/drivers/${driver_id}`, data)
    }

    static getDriverBankAccount(driver_id) {
        return FasterApi.call('GET', `/drivers/${driver_id}/bankaccount`)
    }

    static getWalletTransactions(user_id) {
        return FasterApi.call('GET', `/users/${user_id}/wallet/transactions`)
    }

    static createWalletTransactions(user_id, data) {
        return FasterApi.call('POST', `/users/${user_id}/wallet/transactions`, data)
    }

    static getUserCompanies() {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/users/me/companies`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        store.dispatch({
                            type: 'SET_USER_COMPANIES',
                            payload: res.data,
                        })
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static getKeyWordPlaces({ keyword, point_lat, point_lon }) {
        return new Promise((resolve, reject) => {
            fetch(`${FasterApi.baseUrl}/places/predictions?keyword=${keyword}&point_lat=${point_lat}&point_lon=${point_lon}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + FasterApi.getToken(),
                },
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            })
        })
    }

    static qsGenerator({ filter, limit, sort, page }) {
        var qs = '?'

        if (filter) {
            for (let key in filter) {
                qs += `${key}=${filter[key]}&`
            }
        }

        console.log(sort)

        if (limit) {
            qs += 'limit=' + limit + '&'
        }

        if (sort) {
            qs += 'sort=' + sort + '&'
        }

        if (page) {
            qs += 'offset=' + (page * limit - limit) + '&'
        }

        console.log(sort)

        return qs.substring(0, qs.length - 1)
    }
}
