import React, { useEffect, useState } from 'react'
import {
    Link,
    useLocation
} from 'react-router-dom'
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    VerticalBarSeriesCanvas,
    DiscreteColorLegend
} from 'react-vis';
import moment from 'moment'

import FasterApi from '../../Services/FasterApi'

import PrettyETHTable from '../../Components/PrettyETHTable'


import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import S3Picture from '../../Components/S3Picture'

export function Single(props) {

    let [driver, setDriver] = useState({})
    let [menu, setMenu] = useState(1)
    let [reviews, setReviews] = useState([])



    let reload = () => {
        FasterApi.getDriver({ driver_id: 'me' }).then(({ data }) => {
            console.log("driver: ", data)
            setDriver(data)
            if (data) {
                FasterApi.getMyDriverRates(data.id).then(({ data }) => {
                    console.log("reviews: ", data.rows)
                    setReviews(data.rows)
                })

            }
        })


    }

    useEffect(() => {
        reload()
    }, [])

    if (!driver.id) {
        return (<div></div>)
    }

    return (
        <div className="content-area-wrapper faster-logo-bg">
            <div className="main-content">
                <div className="page-title-sec pad-tb-1 space-between d-flex t-flex-start">
                    <div className="page-sec-nav">
                    </div>
                </div>
            </div>

            <div className="page-content-sec">
                <div className="main-content mb-50">
                    <div className="two-columns-wrapper d-flex space-between eq-he-cols">

                        <div className="profile-sidebar box-shadow3 bg-white-sec">
                            <h3 className="align-center">
                                {/* <img className="diamond-icon mr-10" src="/images/diamond-icon.svg" alt="" /> */}
                            {driver.User.firstname} {driver.User.lastname}</h3>
                            <div className="profile-img-sec m-auto">
                                <div className="profile-pic img-preview">
                                    <S3Picture type="user-profil" file={driver.User.picture} />
                                </div>
                            </div>
                     
                            <div className="align-center mt-25 profile-bottom-link">
                                <a href="#" className="btn-inline-block fw-400 color3">Supprimer mon compte</a>
                            </div>
                        </div>

                        <div className="profile-cont-sec box-shadow3 bg-white-sec">
                            <h3 className="theme-color fw-600">Informations</h3>

                            <div id="chauffeur-section" className="tabs-section">
                                <div className="form-wrapper">
                                    <div className="d-flex flex-wrap eq-he-cols space-between">

                                        <div className="form-col form-col1">
                                            <label>Prénom</label>
                                            <div className="form-control control-icon-right">
                                                <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                <input type="text" className="th-textbox" name="Prenom" placeholder="Prénom" />
                                            </div>
                                        </div>

                                        <div className="form-col form-col2">
                                            <label>Nom</label>
                                            <div className="form-control control-icon-right">
                                                <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                <input type="text" className="th-textbox" name="Nom"  />
                                            </div>
                                        </div>

                                        <div className="form-col form-col1">
                                            <label>Numéro de téléphone</label>
                                            <div className="form-control control-icon-right">
                                                <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                <input type="text" className="th-textbox" name="téléphone" />
                                            </div>
                                        </div>

                                        <div className="form-col form-col2">
                                            <label>Adresse e-mail</label>
                                            <div className="form-control control-icon-right">
                                                <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                <input type="text" className="th-textbox" name="equipements" placeholder="e-mail"  />
                                            </div>
                                        </div>


                                    </div>
                                    {/* <div className="align-right mt-40 btn-fix-mb">
                                        <input type="submit" className="theme-btn min-w-230 mt-10" value="Valider" />
                                    </div> */}
                                </div>
                            </div>


                    </div>

                    

                </div>

                <div className="profile-cont-sec box-shadow3 bg-white-sec" style={{width: "100%"}}>
                            <h3 className="theme-color fw-600">Documents chauffeur</h3>

                            <div id="chauffeur-section" className="tabs-section">
                                <div className="form-wrapper">
                                    <div className="d-flex flex-wrap eq-he-cols space-between">

                                        {/* <div className="form-col form-col1">
                                            <label>Prénom</label>
                                            <div className="form-control control-icon-right">
                                                <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                <input type="text" className="th-textbox" name="Prenom" placeholder="Prénom" />
                                            </div>
                                        </div>

                                        <div className="form-col form-col2">
                                            <label>Nom</label>
                                            <div className="form-control control-icon-right">
                                                <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                <input type="text" className="th-textbox" name="Nom"  />
                                            </div>
                                        </div>

                                        <div className="form-col form-col1">
                                            <label>Numéro de téléphone</label>
                                            <div className="form-control control-icon-right">
                                                <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                <input type="text" className="th-textbox" name="téléphone" />
                                            </div>
                                        </div>

                                        <div className="form-col form-col2">
                                            <label>Adresse e-mail</label>
                                            <div className="form-control control-icon-right">
                                                <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                <input type="text" className="th-textbox" name="equipements" placeholder="e-mail"  />
                                            </div>
                                        </div> */}


                                    </div>
                                    {/* <div className="align-right mt-40 btn-fix-mb">
                                        <input type="submit" className="theme-btn min-w-230 mt-10" value="Valider" />
                                    </div> */}
                                </div>
                            </div>


                    </div>
            </div>

        </div>

        </div>
    )

}