import React, { useEffect, useState } from 'react'
import {
    Link,
    useLocation
} from 'react-router-dom'
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    VerticalBarSeriesCanvas,
    DiscreteColorLegend
} from 'react-vis';
import moment from 'moment'

import FasterApi from '../../Services/FasterApi'

import PrettyETHTable from '../../Components/PrettyETHTable'

import { useSelector } from 'react-redux'


import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import S3Picture from '../../Components/S3Picture'

import ReactS3Uploader from 'react-s3-uploader';

import { toast } from 'react-toastify'

import { useForm } from "react-hook-form";

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

export function Single(props) {

    let [driver, setDriver] = useState({})
    let [menu, setMenu] = useState(1)
    let [reviews, setReviews] = useState([])
    let [me, setMe] = useState({})
    let [bankAccount, setBankAccount] = useState({})

    let { register, handleSubmit, watch, errors, formState, setValue } = useForm({
        mode: 'all'
    });

    if (me && me.role == 2 || me.role == 3) {
        props.history.push('/admin/transactions');
    }

    let reload = () => {

        setValue('IBAN', "N/R", {shouldValidate: true})
        setValue('BIC', "N/R", {shouldValidate: true})

        FasterApi.getMe().then((res) => {
            setMe(res)

            setValue('firstname', res.firstname, {shouldValidate: true})
            setValue('lastname', res.lastname, {shouldValidate: true})
            // setValue('phone_number', res.phone_number, {shouldValidate: true})
            setValue('email', res.email, {shouldValidate: true})

        })

        FasterApi.getDriverBankAccount('me').then((res) => {

            if (!res.errors) {
                setBankAccount(res.data)
                setValue('fullname', res.data.fullname, {shouldValidate: true})
                setValue('IBAN', res.data.IBAN, {shouldValidate: true})
                setValue('BIC', res.data.BIC, {shouldValidate: true})
            }

        })
        
        FasterApi.getDriver({ driver_id: 'me' }).then((res) => {

            if (!res.errors) {
                setDriver(res.data)
                setValue('structure_social_reason', res.data.structure_social_reason, {shouldValidate: true})
                setValue('structure_registration_number', "N/R", {shouldValidate: true})
                setValue('structure_tva_number', "N/R", {shouldValidate: true})
                setValue('structure_address', res.data.structure_address, {shouldValidate: true})
            }

        })

    }

    let updateMe = ({firstname, lastname, phone_number, birthdate}) => {
        
		return new Promise((resolve, reject) => {

			FasterApi.updateMyAccount({firstname, lastname, phone_number, birthdate}).then((data) => {

                if (!data.errors) {
                    resolve();
                } else {
                    reject();

                    toast.error(
                        'Une erreur est survenue lors de la modification de vos informations personnelles, merci de vérifier les informations renseignées.',
                        {
                            position: toast.POSITION.TOP_RIGHT
                        }
                    );
                }
                
                
                
            })
		});

	}

    let updateBankaccount = ({firstname, lastname, IBAN, BIC}) => {

		return new Promise((resolve, reject) => {

			FasterApi.postDriverBankaccount('me', {
				fullname: firstname + " " + lastname,
				IBAN: IBAN,
				BIC: BIC
			}).then((data) => {

                if (!data.errors) {
                    resolve();

                    // toast.success('Le compte bancaire a bien été enregistré.', {
                    //     position: toast.POSITION.TOP_RIGHT
                    // });

                } else {
                    reject();

                    toast.error(
                        'Une erreur est survenue lors de la modification du compte bancaire, merci de vérifier les informations renseignées.',
                        {
                            position: toast.POSITION.TOP_RIGHT
                        }
                    );
                }

                

            })

		});

	}

    let createOrUpdateDriver = ({structure_social_reason, structure_address, structure_registration_number, structure_tva_number}) => {

		return new Promise((resolve, reject) => {

			FasterApi.postDriver('me', {


				structure_social_reason: structure_social_reason,
				structure_address: structure_address,
				structure_registration_number: structure_registration_number,
				structure_tva_number: structure_tva_number

			}).then((data) => {

                if (!data.errors) {
                    resolve();

                    toast.success('Votre compte chauffeur a bien été enregistré.', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    reject();

                    toast.error(
                        'Une erreur est survenue lors de la modification de vos informations de votre compte chauffeur.',
                        {
                            position: toast.POSITION.TOP_RIGHT
                        }
                    );
                }

            })

		});

	}

    useEffect(() => {
        reload()
    }, [])

    if (!me) {
        return <></>
    }

    return (
        <div className="content-area-wrapper faster-logo-bg">
            <div className="main-content">
                <div className="page-title-sec pad-tb-1 space-between d-flex t-flex-start">
                    <div className="page-sec-nav">
                    </div>
                </div>
            </div>

            <form onSubmit={handleSubmit((data) => {

                updateMe({...data, birthdate: me.birthdate}).then(() => {
                    createOrUpdateDriver(data).then(() => {
                        updateBankaccount(data).then(() => {
                            reload();
                        });
                    });
                });

            })}>

                <div className="page-content-sec">
                    <div className="main-content mb-50">
                        <div className="two-columns-wrapper d-flex space-between eq-he-cols">

                            <div className="profile-sidebar box-shadow3 bg-white-sec">
                                <h3 className="align-center">
                                    {me.firstname} {me.lastname}</h3>
                                    <div className="profile-img-sec m-auto">
                                        <div className="profile-pic img-preview">
                                            <S3Picture type="user-profil" file={me.picture} />
                                        </div>
                                    </div>

                                    <ul className="mlr-25-minus mt-40">
                                        <li className={`${menu === 1 && "p-active-link"}`}>
                                            <a onClick={() => setMenu(1)} >Informations <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                        </li>

                                        {/* {driver.id && (
                                            <>

                                                <li className={`${menu === 6 && "p-active-link"}`}>
                                                    <a onClick={() => setMenu(6)} >Documents <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                                </li>

                                               
                                            </>
                                        )} */}

                                        {/* {driver.status === true && (
                                            <>

                                                <li className={`${menu === 5 && "p-active-link"}`}>
                                                    <a onClick={() => setMenu(5)} >Véhicules <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                                </li>

                                                <li className={`${menu === 2 && "p-active-link"}`}>
                                                    <a onClick={() => setMenu(2)} >Revenus <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                                </li>

                                            </>
                                        )} */}


                                    </ul>
                                    
                                    <br />

                                    {driver.status === true && (
                                        <p style={{ color: 'green' }}>
                                            Vous faites parti de Faster ! Vous pouvez éffectuer des courses lorsque vous voulez.
                                        </p>
                                    )}
                                    
                                    {driver.status === false && (
                                        <p style={{ color: 'red' }}>
                                            Vous ne faites pas encore parti de Faster, votre compte est en attente de validation.
                                        </p>
                                    )}
                                    
                            </div>
                            
                            {
                                menu === 1 && (
                                    <div className="profile-cont-sec box-shadow3 bg-white-sec">
                                        <h3 className="theme-color fw-600">Informations</h3>

                                        <div id="chauffeur-section" className="tabs-section">
                                            <div className="form-wrapper">
                                                <div className="d-flex flex-wrap eq-he-cols space-between">

                                                    <div className="form-col form-col1">
                                                        <label>Prénom</label>
                                                        <div className="form-control control-icon-right">
                                                            <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                            <input type="text" className="th-textbox" name="firstname" placeholder="Prénom" ref={register({ required: true })} />
                                                        </div>
                                                    </div>

                                                    <div className="form-col form-col2">
                                                        <label>Nom</label>
                                                        <div className="form-control control-icon-right">
                                                            <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                            <input type="text" className="th-textbox" name="lastname" ref={register({ required: true })}  />
                                                        </div>
                                                    </div>

                                                    {/* <div className="form-col form-col1">
                                                        <label>Numéro de téléphone</label>
                                                        <div className="form-control control-icon-right">
                                                            <input type="text" className="th-textbox" name="phone_number" ref={register({ required: true })} disabled/>
                                                        </div>
                                                    </div> */}

                                                    <div className="form-col form-col2">
                                                        <label>Adresse e-mail</label>
                                                        <div className="form-control control-icon-right">
                                                            {/* <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg> */}
                                                            <input type="text" className="th-textbox" name="email" ref={register({ required: true })} disabled placeholder="e-mail" />
                                                        </div>
                                                    </div>

                                                    {/* <div className="form-col form-col1">
                                                        <label>Date de naissance</label>
                                                        <div className="form-control control-icon-right">

                                                            <DatePicker
                                                                className="th-textbox"
                                                                selected={me.birthdate}
                                                                placeholder="01/02/1980"
                                                                onChange={(date) => setMe({...me, birthdate: date})}
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                        </div>
                                                    </div> */}

                                                </div>
                                                
                                            </div>
                                        </div>

                                        <h3 className="theme-color fw-600">Adhésion chauffeur</h3>

                                        <div id="chauffeur-section" className="tabs-section">
                                            <div className="form-wrapper" style={{display: 'flex'}}>
                                                <div className="d-flex flex-wrap eq-he-cols space-between" >


                                                <div className="form-col form-col-1">
                                                        <label>Raison sociale ou nom professionnel</label>
                                                        <div className="form-control control-icon-right">
                                                            <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                            <input type="text" className="th-textbox" placeholder="Nom de la société" name="structure_social_reason" ref={register({ required: true })} />
                                                        </div>
                                                    </div>

                                                    <div className="form-col form-col-1">
                                                        <label>Adresse</label>
                                                        <div className="form-control control-icon-right">
                                                            <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                            <input type="text" className="th-textbox" placeholder="Adresse de la société" name="structure_address" ref={register({ required: true })} />
                                                        </div>
                                                    </div>

                                                    {/* <div className="form-col form-col-1">
                                                        <label>Numéro d’immatriculation</label>
                                                        <div className="form-control control-icon-right">
                                                            <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                            <input type="text" className="th-textbox" placeholder="Numéro d’immatriculation" name="structure_registration_number" ref={register({ required: true })} />
                                                        </div>
                                                    </div>

                                                    <div className="form-col form-col-2">
                                                        <label>Numéro TVA</label>
                                                        <div className="form-control control-icon-right">
                                                            <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                            <input type="text" className="th-textbox" placeholder="Numéro TVA" name="structure_tva_number" ref={register({ required: true })} />
                                                        </div>
                                                    </div> */}

                                                    <div className="form-col form-col-1" style={{"display": "none"}}>
                                                        <label>IBAN</label>
                                                        <div className="form-control control-icon-right">
                                                            <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                            <input type="text" className="th-textbox" placeholder="Numéro IBAN" name="IBAN" ref={register({ required: true })} />
                                                        </div>
                                                    </div>

                                                    <div className="form-col form-col-2" style={{"display": "none"}}>
                                                        <label>BIC</label>
                                                        <div className="form-control control-icon-right">
                                                            <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg>
                                                            <input type="text" className="th-textbox" placeholder="Numéro BIC" name="BIC" ref={register({ required: true })}  />
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>

                                        {driver.id && (

                                            <>

                                                <h3 className="theme-color fw-600">Documents</h3>

                                                <div id="documents-section" className="tabs-section">
                                                    <div className="form-wrapper">
                                                        <div className="d-flex flex-wrap eq-he-cols space-between">

                                                            <SingleDocument driver={driver} title="Carte d’identité Recto" type="card_id" />
                                                            <SingleDocument driver={driver} title="Carte d’identité Verso" type="card_id_back" />
                                                            <SingleDocument driver={driver} title="Permis de conduire" type="card_driver_license" />

                                                        </div>
                                                        
                                                    </div>
                                                </div>

                                            </>

                                            
                                        )}

                                        
                                        <div className="align-right mt-40 btn-fix-mb">
                                            <input type="submit" className="theme-btn min-w-230 mt-10" value={driver.status ? "Envoyer ma demande pour devenir chauffeur !" : "Enregistrer"} style={{ width: '100%', backgroundColor: !formState.isValid && '#9396AD', marginBottom: 20 }}
                                            disabled={!formState.isValid} />
                                        </div>

                                    </div>
                                )
                            }

                            {

                                menu === 6 && driver.id && (
                                    <div className="profile-cont-sec box-shadow3 bg-white-sec">
                                        <h3 className="theme-color fw-600">Documents</h3>
                                
                                        <div id="documents-section" className="tabs-section">
                                            <div className="form-wrapper">
                                                <div className="d-flex flex-wrap eq-he-cols space-between">
                                
                                                    <SingleDocument driver={driver} title="Carte d’identité Recto" type="card_id" />
                                                    <SingleDocument driver={driver} title="Carte d’identité Verso" type="card_id_back" />
                                                    <SingleDocument driver={driver} title="Permis de conduire" type="card_driver_license" />
                                
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                )

                            }
                            

                        </div>


                    </div>

                </div>

            </form>

        </div>
    )

}

function SingleDocument(props) {

    let user = useSelector((state) => state.user)
    let uploader = null
    let toastId = null

    let [data, setData] = useState({
        filename: props.driver.DriverDocument[props.type], 
    })

    let getSignedUrlForPost = (file, callback, type) => {

		FasterApi.getSignedUrlForPost({
			objectName: file.name,
			contentType: file.type,
			path: `documents/${type}/`
		}).then((data) => {
			callback(data);
		})

	}

    let onUploadStart = (file, next, type) => {

		if (file.size <= 10000000) {
            toastId = toast.loading("Votre document est en cours de téléversement.");

			next(file);
		} else {
			toast.error('Votre document ne doit pas dépasser les 10 MO.', {
				position: toast.POSITION.TOP_RIGHT
			});
			uploader.clear();
		}

	}

    let onUploadFinish = (data, type) => {

		FasterApi.postDriverDocument('me', {
			type: type,
			document: data.filename
		}).then(() => {
            toast.update(toastId, { render: "Le document a bien été reçu.", type: "success", isLoading: false, icon: "🚀", autoClose: 3000 });

            setData({
                filename: data.filename,
                error: '',
                expiration: null
            })

		});

	}

    return ( <div className="form-col form-col1">
        <label>{props.title}</label>

        <div className="form-control control-icon-right pad-left-icon">
            <svg className="icon field-left-icon field-mid-icon edit-icon"><use href="/images/svg-sprite.svg#pdf-icon"></use></svg>
            <input type="text" className="th-textbox" name="carte_chauffeur" value={
                (data.filename != '' ? (data.filename) : ('Aucun document envoyé.'))
            } />
        </div> 
            
        <span>

            {
                (data.filename != '' && <a className="theme-btn min-w-230 mt-10" style={{minWidth: "190px", marginRight: "25px"}} onClick={() => {
                        FasterApi.getSignedUrlForGet({path: `documents/${props.type}/${data.filename}`}).then((res) => {
                            window.open(res.url, "_blank", 'noopener,noreferrer')
                        })
                    }}>
                        Voir le document
                    </a>
                )
            }

            <label className="theme-btn min-w-230 mt-10" for={`upload_${props.type}`} style={{maxWidth: '260px'}}>
                Envoyer un document
            </label>

            <ReactS3Uploader
                ref={(ref) => {
                    uploader = ref;
                }}
                id={`upload_${props.type}`}
                className="theme-btn min-w-230 mt-10"
                preprocess={(file, next) => {
                    onUploadStart(file, next, props.type);
                }}
                onFinish={(data) => {
                    onUploadFinish(data, props.type);
                }}
                getSignedUrl={(file, callback) => {
                    getSignedUrlForPost(file, callback, props.type);
                }}
                accept="image/*, application/pdf"
                uploadRequestHeaders={{
                    'x-amz-acl': 'private',
                    'x-amz-meta-owner': user.id.toString()
                }}
                contentDisposition="auto"
                style={{display: "none"}}
            />

        </span>

    </div>
    )
}