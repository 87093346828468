import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Swal from 'sweetalert2'

import 'sweetalert2/dist/sweetalert2.min.css'

import FasterApi from '../Services/FasterApi'

import { useForm } from "react-hook-form";

import {
    Redirect, Link
} from "react-router-dom";

export default function RegisterScreen(props) {

    let emailregx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    let { register, handleSubmit, watch, errors, formState } = useForm({
        mode: 'all'
    });
    

    // if (signed) {
    //     return <Redirect to="/createPhoneNumber"></Redirect>
    // }

 

    return (

        <div className="content-area-wrapper faster-logo-bg bg-logo-pos1">

            <div class="main-container">
                <div class="full-screen-height d-flex flex-column grid-bg-sec">
                    <div class="sm-content-block box-shadow1 lg-white-bg">
                        <div class="faster-logo adjust-logo-sm-con"><img src="images/faster-logo.svg" alt="Faster" /></div>
                        <h3 class="align-center">Création de votre compte</h3>
                        <div class="form-wrapper">

                            <form onSubmit={handleSubmit((data) => {

                                FasterApi.signup({
                                    firstname: data.firstname,
                                    lastname: data.lastname,
                                    email: data.email,
                                    password: data.password,
                                    password_verif: data.password_verif
                                }).then((res) => {
                                    if (res.status === 201) {
                                        toast.success("🚀 Création du compte réussie.")
                                        FasterApi.getMe().then(() => {
                                            props.history.push('/');
                                        })
                                    }
                                    else {
                                        Swal.fire({
                                            title: "Une erreur s'est produite !",
                                            text: FasterApi.handleErrors(res.errors),
                                            showDenyButton: false
                                        })
                                    }

                                })

                            })}>
                                <div class="form-control">
                                    <label>Nom</label>
                                    <div class="">
                                        <input ref={register({ required: true })} type="text" className="th-textbox" name="lastname" placeholder="Votre nom" />

                                    </div>
                                </div>
                                <div class="form-control">
                                    <label>Prénom</label>
                                    <div class="">
                                        <input ref={register({ required: true })} type="text" className="th-textbox" name="firstname" placeholder="Votre prénom" />

                                    </div>
                                </div>
                                <div class="form-control">
                                    <label>E-mail</label>
                                    <div class="">
                                        <input ref={register({ required: true, pattern: emailregx })} type="text" className="th-textbox" name="email" placeholder="Votre email" />

                                    </div>
                                </div>
                                <div class="form-control">
                                    <label>Mot de passe</label>
                                    <div class="ppassword-field ">
                                        <input type="password" ref={register({ required: true })} className="th-textbox" name="password" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" />

                                    </div>
                                </div>
                                <div class="form-control">
                                    <label>Confirmez votre mot de passe</label>
                                    <div class="ppassword-field ">
                                        <input type="password" ref={register({ required: true })} className="th-textbox" name="password_verif" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" />

                                    </div>
                                </div>


                                <input type="submit" className="theme-btn mt-40 d-block upper-case text-shadow1 box-shadow2" value="Valider mon inscription" style={{ width: '100%', backgroundColor: !formState.isValid && '#9396AD', marginBottom: 20 }}
                                    disabled={!formState.isValid}
                                />
                                <Link class="forget-pass " to={'/login'} >
                                    <label>Revenir à la connexion</label>
                                </Link>

                            </form>

                        </div>
                    </div>

                    <div class="connexion-path-wrapper">
                        <div class="circle-path-point"><span>&nbsp;</span></div>
                        <div class="circle-tooltip">
                            <div class="faster-icon tooltip-icon">
                                <span class="faster-logo-icon"><svg class="icon icon-faster-logo icon-white"><use href="/images/svg-sprite.svg#faster-logo"></use></svg></span>
                            </div>
                            <p>Le chauffeur qui vous accompagne dans tous vos déplacements.</p>
                        </div>
                        <div class="connexion-path"><img src="images/connexion-path1.png" alt="Path" /></div>
                    </div>

                </div>
            </div>
        </div>

    );

}
