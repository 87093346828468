import React, { useEffect, useState } from 'react'
import {
    Link,
    useLocation
} from 'react-router-dom'
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    VerticalBarSeriesCanvas
} from 'react-vis';
import moment from 'moment'

import FasterApi from '../../Services/FasterApi'

import PrettyETHTable from '../../Components/PrettyETHTable'


import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import S3Picture from '../../Components/S3Picture'

import Swal from 'sweetalert2'

import { toast } from 'react-toastify';

import Switch from "react-switch";

import { useForm } from "react-hook-form";

export function List(props) {

    const location = useLocation();

    return (
        <PrettyETHTable
            title="Chauffeurs"
            dataLoader={FasterApi.getDrivers}
            dataLoaderParams={props.match.params}

            columns={[
                {
                    title: 'Réf',
                    column: 'id',
                    filter: {
                        type: 'string:equal'
                    }
                },
                {
                    title: 'Nom / Prénom',
                    value: ({ id, User }) => {
                        return <Link className="user-p-link" to={location.pathname + '/' + id}>
                            <S3Picture className="user-profile-img" type="user-profil" file={User.picture} />
                            {User.firstname} {User.lastname}
                        </Link>
                    }

                },
                {
                    title: 'Email',
                    column: 'User.email',
                    value: ({ User }) => User.email
                },
                {
                    title: 'N° de téléphone ',
                    column: 'User.phone_number',
                    value: ({ User }) => User.phone_number
                },
                {
                    title: 'Note',
                    column: 'driver_rate',
                    value: ({ driver_rate }) => (parseFloat(driver_rate)).toFixed(1) + " / 5"
                },
                {
                    title: 'Courses',
                    column: 'trips_amount',
                },

                {
                    title: 'Solde',
                    column: 'wallet_balance',
                    value: ({ wallet_balance }) => {

                        let wb = wallet_balance || 0
                        
                        if (wb === 0) {
                            return <span>0 CFA</span>
                        } else {
                            return <span style={{color: `${wb > 0 ? "green" : "red"}`}}>{wb > 0 ? "+" : "-"} {wb + " CFA"}</span>
                        }

                    }
                },

                {
                    title: 'C.A GÉNÉRÉ',
                    column: 'credited_amount',
                    value: ({ credited_amount }) => credited_amount + " CFA"
                },
                {
                    title: 'Statut',
                    value: (driver) => {

                        return driver.status ? (
                            'Actif'
                        ) : (
                                `Inactif (${Object.keys(
                                    driver.DriverDocument
                                ).filter((a, b) => {
                                    if (
                                        (a === 'card_id' &&
                                            driver.DriverDocument['card_id'] !== '')
                                        ||
                                        (a === 'card_id_back' &&
                                            driver.DriverDocument['card_id_back'] !== '')
                                        ||
                                        (a === 'card_pro_front' &&
                                            driver.DriverDocument['card_pro_front'] !== '')
                                        ||
                                        (a === 'card_pro_back' &&
                                            driver.DriverDocument['card_pro_back'] !== '')
                                        ||
                                        (a === 'card_driver_license' &&
                                            driver.DriverDocument['card_driver_license'] !== '')

                                    ) {
                                        return true;
                                    }

                                    return false;
                                }).length} / 5 docs)`)

                    }
                },


            ]}
        >

        </PrettyETHTable>
    )

}

export function Single(props) {

    let [driver, setDriver] = useState({})
    let [bankaccount, setBankaccount] = useState({})
    let [menu, setMenu] = useState(1)
    let [reviews, setReviews] = useState([])

    let reload = () => {

        FasterApi.getDriver(props.match.params).then(({ data }) => {

            setDriver(data)

            if (data) {
                FasterApi.getMyDriverRates(data.id).then(({ data }) => {
                    setReviews(data.rows)
                })

                FasterApi.getDriverBankAccount(data.id).then(({ data }) => {
                    setBankaccount(data)
                })

            }
        })


    }

    let declineDocument = (type) => {

        Swal.fire({
            title: 'Décliner le document',
			text: 'Pour quelle raison souhaitez vous décliner ce document ?',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonText: "Annuler",
            confirmButtonText: 'Décliner le document',
            dangerMode: true,
        }).then((res) => {

			if (res.isConfirmed) {

				if (res.value !== '') {

					FasterApi.deleteDriverDocument(driver.id, {
						type: type,
						reason: res.value
					}).then((documents) => {

                        reload()

						toast.success('Document refusé avec succès.', {
							position: toast.POSITION.TOP_RIGHT
						});

					});
				} else {

					toast.error("Vous n'avez pas donné de raison.", {
						position: toast.POSITION.TOP_RIGHT
					});

				}

			}

		});
	}

    useEffect(() => {
        reload()
    }, [])

    if (!driver.id) {
        return (<div></div>)
    }

    return (
        <div className="content-area-wrapper faster-logo-bg">
            <div className="main-content">
                <div className="page-title-sec pad-tb-1 space-between d-flex t-flex-start">
                    <div className="page-sec-nav">
                        {/* <a href="#"><svg className="icon icon-arrow-back"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg>Retour</a> */}
                    </div>
                </div>
            </div>

            <div className="page-content-sec">
                <div className="main-content mb-50">
                    <div className="two-columns-wrapper d-flex space-between eq-he-cols">

                        <div className="profile-sidebar box-shadow3 bg-white-sec">
                            <h3 className="align-center">
                                {/* <img className="diamond-icon mr-10" src="/images/diamond-icon.svg" alt="" /> */}
                                {driver.User.firstname} {driver.User.lastname}</h3>

                            <div className="profile-img-sec m-auto">
                                <div className="profile-pic img-preview">
                                    <S3Picture type="user-profil" file={driver.User.picture} />
                                </div>
                            </div>

                            {/* <div className="user-meta-info">
                                <ul className="d-flex flex-wrap">
                                    <li className="li-splitter"><span>note</span><svg className="icon icon-star d-inline-block mr-10"><use href="/images/svg-sprite.svg#star-icon"></use></svg>4.85</li>
                                    <li><span>points</span>132</li>
                                    <li className="full-width-imp"><span>courses</span>10 en 5 jours</li> 
                                </ul>
                            </div> */}

                            <ul className="mlr-25-minus mt-40">

                                <li className={`${menu === 1 && "p-active-link"}`}>
                                    <a onClick={() => setMenu(1)} >Informations <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                </li>

                                <li className={`${menu === 6 && "p-active-link"}`}>
                                    <a onClick={() => setMenu(6)} >Documents <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                </li>

                                <li className={`${menu === 5 && "p-active-link"}`}>
                                    <a onClick={() => setMenu(5)} >Véhicules <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                </li>

                                <li className={`${menu === 2 && "p-active-link"}`}>
                                    <a onClick={() => setMenu(2)} >Revenus <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                </li>

                                <li className={`${menu === 7 && "p-active-link"}`}>
                                    <a onClick={() => setMenu(7)} >Solde 
                                    {
                                         (() => {
                                            let wb = driver.wallet_balance || 0
                                
                                            if (wb === 0) {
                                                return <span> 0 CFA</span>
                                            } else {
                                                return <span style={{color: `${wb > 0 ? "green" : "red"}`}}> {wb > 0 ? "+" : "-"} {wb + " CFA"}</span>
                                            }
                                        })()
                                    }
                                     <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                </li>

                                {/* <li className={`${menu === 3 && "p-active-link"}`}>
                                    <a onClick={() => setMenu(3)} >Avantages chauffeur <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                </li> */}

                                <li className={`${menu === 4 && "p-active-link"}`}>
                                    <a onClick={() => setMenu(4)} >Avis <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                </li>

                            </ul>

                            <div className="switch-option summary-col">

                                <label className="d-flex flex-wrap space-between pad-tb-1">Chauffeur {driver.status ? "validé" : "pas validé"}

                                    <div className="switch mt-0 dispo-switch">

                                        <Switch onChange={(checked) => {

                                            FasterApi.updateDriverStatus(driver.id).then((driver) => {
                                                reload()

                                                if (driver.status === true) {
                                                    toast.success("Le chauffeur fait désormais parti de Faster !", {
                                                        position: toast.POSITION.TOP_RIGHT
                                                    });
                                                } else {
                                                    toast.success('Le chauffeur quitte désormais Faster !', {
                                                        position: toast.POSITION.TOP_RIGHT
                                                    });
                                                }
                                            });
                                          
                                        }} checked={driver.status} />

                                    </div>

                                </label>

                                {driver.status === true && (
                                    <p style={{ color: 'green' }}>
                                        Ce chauffeur fait parti de Faster, son adhésion a déjà été accepté, vous pouvez arrêter son adhésion à gauche de la page.
                                    </p>
                                )}

                                {driver.status === false && (
                                    <p style={{ color: 'red' }}>
                                        Ce chauffeur n'est pas encore en activité chez vous, vous devez accepter son adhésion à gauche de la page après avoir valider tous les documents.
                                    </p>
                                )}

                            </div>

                        </div>

                        {menu == 1 && <Informations {...props} driver={driver} bankaccount={bankaccount} reload={reload}  />}
                        {menu == 2 && <Incomes {...props} driver={driver} />}
                        {menu == 3 && <Advantages {...props} driver={driver} />}
                        {menu == 4 && <Rate {...props} driver={driver} reviews={reviews} />}
                        {menu == 5 && <Vehicles {...props} driver={driver} />}
                        {menu == 6 && <Documents {...props} driver={driver} declineDocument={declineDocument}/>}
                        {menu == 7 && <Wallet {...props} driver={driver} reload={reload} />}

                    </div>
                </div>
            </div>

        </div>
    )

}

function Informations(props) {

    return (<div className="profile-cont-sec box-shadow3 bg-white-sec">
        <h3 className="theme-color fw-600">Informations</h3>

        <div className="tabs-section">

            <div className="form-wrapper">
                <div className="d-flex flex-wrap eq-he-cols space-between">
                  

                    <div className="form-col form-col1">
                        <label>Prénom</label>
                        <div className="form-control control-icon-right">
                            {/* <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg> */}
                            <input type="text" className="th-textbox" name="Prenom" placeholder="Prénom" value={props.driver.User.firstname} />
                        </div>
                    </div>

                    <div className="form-col form-col2">
                        <label>Nom</label>
                        <div className="form-control control-icon-right">
                            {/* <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg> */}
                            <input type="text" className="th-textbox" name="Nom" value={props.driver.User.lastname} />
                        </div>
                    </div>

                    <div className="form-col form-col1">
                        <label>Numéro de téléphone</label>
                        <div className="form-control control-icon-right">
                            {/* <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg> */}
                            <input type="text" className="th-textbox" name="téléphone" value={props.driver.User.phone_number} />
                        </div>
                    </div>

                    <div className="form-col form-col2">
                        <label>Adresse e-mail</label>
                        <div className="form-control control-icon-right">
                            {/* <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg> */}
                            <input type="text" className="th-textbox" name="equipements" placeholder="e-mail" value={props.driver.User.email} />
                        </div>
                    </div>


                </div>
               
            </div>

            <hr />
            <br />

            <div className="form-wrapper" style={{display: 'flex'}}>

                <div className="d-flex flex-wrap eq-he-cols space-between" style={{width: '100%'}}>

                    <div className="form-col form-col1">
                        <label>Raison sociale</label>
                        <div className="form-control control-icon-right">
                            <input type="text" className="th-textbox" name="Prenom" placeholder="Prénom" value={props.driver.structure_social_reason} />
                        </div>
                    </div>

                    <div className="form-col form-col2">
                        <label>Adresse</label>
                        <div className="form-control control-icon-right">
                            <input type="text" className="th-textbox" name="Nom" value={props.driver.structure_address} />
                        </div>
                    </div>

                     {/* <div className="form-col form-col1"> */}
                        {/* <label>Numéro d’immatriculation</label>
                        <div className="form-control control-icon-right">
                            <input type="text" className="th-textbox" name="téléphone" value={props.driver.structure_registration_number} />
                        </div>*/}
                    {/* </div>  */}

                    {/* <div className="form-col form-col2"> */}
                        {/* <label>Numéro TVA</label>
                        <div className="form-control control-icon-right">
                            <input type="text" className="th-textbox" name="equipements" placeholder="e-mail" value={props.driver.structure_tva_number} />
                        </div> */}
                    {/* </div> */}

                    {
                        (props.bankaccount && (
                            <>
                                <div className="form-col form-col1">
                                    <label>IBAN</label>
                                    <div className="form-control control-icon-right">
                                        {/* <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg> */}
                                        <input type="text" className="th-textbox" name="téléphone" value={props.bankaccount.IBAN} />
                                    </div>
                                </div>

                                <div className="form-col form-col2">
                                    <label>BIC</label>
                                    <div className="form-control control-icon-right">
                                        {/* <svg className="icon icon-field-right edit-icon"><use href="/images/svg-sprite.svg#edit-icon"></use></svg> */}
                                        <input type="text" className="th-textbox" name="equipements" placeholder="e-mail" value={props.bankaccount.BIC} />
                                    </div>
                                </div>
                            </>
                        ))
                    }


                </div>

            </div>

        </div>

    </div>

    )

}

function Incomes(props) {

    let [currentDateTotal, setCurrentDateTotal] = useState(moment())
    let [currentDateActivities, setCurrentDateActivities] = useState(moment())
    let [useCanvas, setUseCanvas] = useState(false)
    let [activities, setActivities] = useState([])
    let [driverSummary, setDriverSummary] = useState([])
    let [widthdraws, setWidthdraws] = useState([])


    let [menu, setMenu] = useState(1)

    const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;

    let reload = () => {

        FasterApi.getMyDriverSummary(props.driver.id, moment(currentDateTotal).format('MM-YYYY')).then((res) => {
            setDriverSummary(res.data)
        })

        FasterApi.getDriverWidthdraws({driver_id: props.driver.id}).then((res) => {
            setWidthdraws(res.data.rows)
        })

        FasterApi.getMyDriverActivitySummary(props.driver.id, moment(currentDateActivities).format('DD-MM-YYYY')).then(({ data }) => {
           
            let days = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim']
            let stats = []

            for (let index = 0; index < days.length; index++) {
                const day = days[index];
                const value = data[index];
                stats.push({ x: day, y: value / 3600 })
            }

            setActivities(stats)
        })

    }

    function toHHMMSS(duration) {
        var sec_num = parseInt(duration, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ' : ' + minutes + ' : ' + seconds;
    }

    useEffect(() => {
        console.log('hey')
        reload()
    }, [currentDateActivities, currentDateTotal])

    return (<div className="profile-cont-sec box-shadow3 bg-white-sec">
        <h3 className="theme-color fw-600">Revenus</h3>

        <div className="section-tabs-label sec-border-fix">
            <ul>
                <li><a className={`${menu === 1 && "active-sec"}`} onClick={() => setMenu(1)}  >Tableau de bord</a></li>
                {/* <li><a className={`${menu === 2 && "active-sec"}`} onClick={() => setMenu(2)} >Versements mensuels</a></li> */}
            </ul>
        </div>

        { menu === 1 && <div id="tableau-section" className="tabs-section">
            <div className="align-center">
                <span className="sm-label">mois</span>
                <div className="datepicker-calender mb-50">
                    <div className="datepicker-icon sm-inline-datepicker-wrap pad-right-icon pad-left-icon align-center">
                        <svg className="field-left-icon icon icon-arrow-down pointer" style={{ zIndex: 9999 }} onClick={() => setCurrentDateTotal(moment(currentDateTotal).add(-1, 'month'))} ><use href="/images/svg-sprite.svg#d-arrow-black" ></use></svg>
                        {moment(new Date()).isAfter(moment(currentDateTotal), 'month') &&
                            <svg className="field-right-icon icon icon-arrow-down pointer" style={{ zIndex: 9999 }} onClick={() => setCurrentDateTotal(moment(currentDateTotal).add(1, 'month'))}><use href="/images/svg-sprite.svg#d-arrow-black" ></use></svg>
                        }
                        <input type="text" name="monthpicker" className="th-textbox-inline sm-inline-datepicker monthpicker m-auto" value={currentDateTotal.format('MMMM YYYY')} disabled />
                    </div>
                </div>
            </div>

            <div className="graphs-section-wrap d-flex flex-wrap flex-start-align">
                <div class="graph-col graph-col-size1 align-center">
                    <span class="sm-label">HEURES D’ACTIVITÉ</span>
                    <h4 class="fs-18 fw-500">{toHHMMSS(driverSummary.drive_duration)}</h4>
                    <div class="graph-sec graph-img">
                        <div id="pie-chart1"></div>
                    </div>
                    <span class="sm-label">courses</span>
                    <h4 class="fs-18 fw-500">{driverSummary.trips_count}</h4>
                </div>

                <div class="graph-col graph-col-size1 align-center">
                    <span class="sm-label">REVENUS</span>
                    <h4 class="fs-18 fw-500">{driverSummary.income_amount + " CFA"}</h4>
                    <div class="graph-sec graph-img"><div id="pie-chart2"></div></div>
                    <span class="sm-label">points</span>
                    <h4 class="fs-18 fw-500">{driverSummary.points}</h4>
                </div>
                <div className="graph-col graph-col-size2">
                    <div className="align-center">
                        <span className="sm-label">semaine</span>
                        <div className="datepicker-calender pb-20">
                            <div className="datepicker-icon sm-inline-datepicker-wrap pad-right-icon pad-left-icon align-center">
                                <svg className="field-left-icon icon icon-arrow-down pointer" style={{ zIndex: 9999 }} onClick={() => setCurrentDateActivities(moment(currentDateActivities).add(-1, 'week'))}><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg>
                                {moment(new Date()).startOf('week').isAfter(moment(currentDateActivities).startOf('week'), 'week')
                                    &&
                                    <svg className="field-right-icon icon icon-arrow-down pointer" style={{ zIndex: 9999 }} onClick={() => setCurrentDateActivities(moment(currentDateActivities).add(1, 'week'))}><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg>
                                }
                                <input type="text" name="weekpicker" className="th-textbox-inline sm-inline-datepicker weekpicker m-auto" />
                                <span className="fs-18 fw-600 theme-color"><span id="startDate"></span> {currentDateActivities.startOf("week").format('LL')} <span id="endDate"></span></span>
                            </div>
                        </div>
                    </div>
                    <div id="chart" style={{ "width": "100%", "height": "170px" }} className="mb-30">
                        <XYPlot
                            className="clustered-stacked-bar-chart-example"
                            xType="ordinal"
                            stackBy="y"
                            width={300}
                            height={300}
                        >
                          
                            <VerticalGridLines />
                            <HorizontalGridLines />
                            <XAxis />
                            <YAxis />

                            <BarSeries
                                cluster="2016"
                                color="#79C7E3"
                                data={activities}
                            />
                        </XYPlot>

                    </div>

                </div>
            </div>

        </div> }

        { menu === 2 && <div className="tabs-section">

            <div className="table-wrapper s-table-content-wrapper">
                <div className="d-table full-width s-table-content">
                    <div className="d-table-row table-header">

                        <div className="d-table-cell align-center"></div>

                        <div className="d-table-cell">
                            <span className="c-d-arrow">Date </span>
                        </div>
                        
                        <div className="d-table-cell">
                            <span className="c-d-arrow">Montant </span>
                        </div>
                        
                        <div className="d-table-cell">
                            <span className="c-d-arrow">Réf virement </span>
                        </div>

                        <div className="d-table-cell">
                            <span className="c-d-arrow">Statut </span>
                        </div>

                    </div>

                    {widthdraws.map((widthdraw) => {

                        return (
                            <div className="d-table-row table-data-row">
                                <div className="d-table-cell align-center"></div>
                                <div className="d-table-cell">{moment(widthdraw.from_date).format("DD MMMM")} - {moment(widthdraw.to_date).format("DD MMMM YYYY")}</div>
                                
                                <div className="d-table-cell">{widthdraw.total_driver_fees_amount} CFA</div>

                                <div className="d-table-cell">{widthdraw.bank_reference || "Aucun"}</div>

                                <div className="d-table-cell">{`${widthdraw.status === false ? 'En attente' : 'Envoyé'} `}</div>

                            </div>
                        )

                    }) }


                </div>
            </div>

        </div> }


    </div>)

}

function Advantages(props) {

    return (

        <div className="profile-cont-sec box-shadow3 bg-white-sec">
            <h3 className="theme-color fw-600">Avantages chauffeur</h3>

            <div className="sec-border-fix pt-20">
                <div className="page-sec-nav fs-16 fw-500">
                    {/* <a href="#"><svg className="icon icon-arrow-back"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg>Retour avantages</a> */}
                </div>
                <div className="align-center">
                    <div className="total-logo-big"><img src="/images/TOTAL_SA_logo.svg" alt="Total" /></div>
                    <p className="fs-20 fw-500 pb-20">Bénéficiez de -10% sur votre plein d’essence*, <br />en utilisant le QRCODE ci-dessous :</p>
                    <div className="qr-code"><img src="/images/QR_code.png" alt="QR Code" /></div>
                    <p className="color-purple align-right">*Offre valable jusqu’au 25 août 2020</p>
                    <a href="#" className="theme-btn min-w-280 mt-40 d-inline-block upper-case box-shadow2">renvoyer par e-mail</a>
                </div>
            </div>

        </div>
    )

}

function Rate(props) {

    return (
        <div className="profile-cont-sec box-shadow3 bg-white-sec">
            <h3 className="theme-color fw-600">Avis</h3>

            <div className="sec-border-fix pt-20">
                <div className="avis-rows-wrapper mlr-60-minus">

                    {
                        (() => { 

                            if (props.reviews.length > 0) {

                                return props.reviews.map((el) => {
                                    return (
                                        <div className="avis-row d-flex flex-wrap flex-start-align space-between" style={{'border-bottom': '1px solid #00000029'}}>
                                            <div className="avis-user-info">
                                                <div className="avis-user-img d-inline-block">
                                                    <S3Picture  type="user-profil" file={el.Passenger.picture} />
                                                </div>
                                                <div className="avis-user-meta d-inline-block">
                                                    <h3>{el.Passenger.firstname + " " + el.Passenger.lastname}</h3>
                                                    <span className="d-block fw-600 theme-color"><svg className="icon icon-star mr-10"><use href="/images/svg-sprite.svg#star-icon"></use></svg> {(parseFloat(el.rate)).toFixed(1)}</span>
                                                    <span className="d-block">le {moment(el.created_at).format('DD/MM/YYYY')}</span>
                                                </div>
                                            </div>
                                            <div className="avis-user-desc">
        
                                                <p>{el.comment}</p>
                                                
                                                    <div class="summary-col">
                                                        <p>
                                                            <Link style={{ marginTop: 20, }} className="theme-color" to={'/admin/trips/' + el.trip_id} style={{ textDecorationLine: 'underline' }}>
                                                                Voir la course
                                                            </Link>
                                                        </p>
                                                    </div>
                                                
        
                                            </div>
                                        </div>
                                    )
                                })

                            } else {

                                return (<div className="avis-row d-flex flex-wrap flex-start-align space-between">
                                    
                                    <div className="avis-user-desc">

                                        <p>L'utilisateur n'a encore aucun avis</p>
                                    </div>
                                </div>
                                )

                            }

                        })()
                        
                    }

                </div>

            </div>

        </div>
    )

}


function Vehicles(props) {

    let [vehicles, setVehicles] = useState([])

    let reload = () => {
        FasterApi.getVehicles(props.driver.id).then((data) => {
            setVehicles(data)
        })
    }

    useEffect(() => {
        reload()
    }, [])

    return (

            <div class="table-wrapper s-table-content-wrapper profile-cont-sec" style={{padding: "0"}}>

                <div class="d-table full-width s-table-content">
                    <div class="d-table-row table-header" >

                        <div class="d-table-cell">
                        </div>

                        <div class="d-table-cell">
                            <span class="c-d-arrow" style={{ fontSize: 16 }}>Nom <svg class="icon icon-arrow-down icon-white"><use href="images/svg-sprite.svg#d-arrow-black"></use></svg></span>
                        </div>
                        <div class="d-table-cell">
                            <span class="c-d-arrow" style={{ fontSize: 16 }}>Immatriculation <svg class="icon icon-arrow-down icon-white"><use href="images/svg-sprite.svg#d-arrow-black"></use></svg></span>
                        </div>
                        <div class="d-table-cell">
                            <span class="c-d-arrow" style={{ fontSize: 16 }}>Type de véhicule <svg class="icon icon-arrow-down icon-white"><use href="images/svg-sprite.svg#d-arrow-black"></use></svg></span>
                        </div>

                        <div class="d-table-cell">
                            <span class="c-d-arrow" style={{ fontSize: 16 }}>Numéro assurance <svg class="icon icon-arrow-down icon-white"><use href="images/svg-sprite.svg#d-arrow-black"></use></svg></span>
                        </div>
                        <div class="d-table-cell">
                            <span class="c-d-arrow" style={{ fontSize: 16 }}>Carte grise<svg class="icon icon-arrow-down icon-white"><use href="images/svg-sprite.svg#d-arrow-black"></use></svg></span>
                        </div>
                        <div class="d-table-cell">
                            <span class="c-d-arrow" style={{ fontSize: 16 }}>Documents<svg class="icon icon-arrow-down icon-white"><use href="images/svg-sprite.svg#d-arrow-black"></use></svg></span>
                        </div>

                        <div class="d-table-cell">
                        </div>
                        
                    </div>
                    {vehicles.map((el) => {
                        return (
                            <div class="d-table-row table-data-row">
                                <div class="d-table-cell"></div>
                                <div class="d-table-cell">{el.name}</div>
                                <div class="d-table-cell">{el.plate_number}</div>
                                <div class="d-table-cell">{el.VehicleType.name}</div>
                                <div class="d-table-cell">{el.insurance_number}</div>
                                <div class="d-table-cell">{el.vehicle_card_number}</div>
                                {  el.picture_front.length > 0 ?
                                    <div class="d-table-cell" >
                                        <a href={"https://ezee-app-public-dev.s3.eu-central-1.amazonaws.com/vehicles/picture_front/" + el.picture_front} target="_blank" >Voir l'avant</a> <br />
                                        <a href={"https://ezee-app-public-dev.s3.eu-central-1.amazonaws.com/vehicles/picture_back/" + el.picture_back} target="_blank" >Voir l'arrière</a> <br />
                                        <a href={"https://ezee-app-public-dev.s3.eu-central-1.amazonaws.com/vehicles/picture_insurance/" + el.picture_insurance} target="_blank" >Voir l'assurance</a> <br />
                                        <a href={"https://ezee-app-public-dev.s3.eu-central-1.amazonaws.com/vehicles/picture_documentation/" + el.picture_documentation} target="_blank" >Voir la carte grise</a> 
                                    </div>
                                : 
                                <div class="d-table-cell" >Aucun document
                                </div>    
                                }
                                <div class="d-table-cell"></div>

                            </div>
                        )
                    })

                    }

                </div>

            </div>

    )

}

function Documents(props) {

    return (
        <div className="profile-cont-sec box-shadow3 bg-white-sec">
            <h3 className="theme-color fw-600">Informations</h3>

            <div id="documents-section" className="tabs-section">
                <div className="form-wrapper">
                    
                    <div className="d-flex flex-wrap eq-he-cols space-between">

                        <SingleDocument {...props} title="Carte d’identité Recto" type="card_id" />
                        <SingleDocument {...props} title="Carte d’identité Verso" type="card_id_back" />
                        <SingleDocument {...props} title="Permis de conduire" type="card_driver_license" />

                    </div>
                    
                </div>
            </div>
        </div>)

}

function SingleDocument(props) {

    

    return ( <div className="form-col form-col1">
        <label>{props.title}</label>

        <div className="form-control control-icon-right pad-left-icon">
            <svg className="icon field-left-icon field-mid-icon edit-icon"><use href="/images/svg-sprite.svg#pdf-icon"></use></svg>
            <input type="text" className="th-textbox" name="carte_chauffeur" value={
                (props.driver.DriverDocument[props.type] != '' ? ( props.driver.DriverDocument[props.type]) : ('Aucun document envoyé.'))
            } />
        </div> 

            { props.driver.DriverDocument[props.type] !== '' ? (
                <span>

                    <button className="theme-btn min-w-230 mt-10" style={{minWidth: "190px", marginRight: "25px"}} onClick={() => {
                        FasterApi.getSignedUrlForGet({path: `documents/${props.type}/${props.driver.DriverDocument[props.type]}`}).then((res) => {
                            window.open(res.url, "_blank", 'noopener,noreferrer')
                        })
                    }}>
                        Voir le document
                    </button>

                    <button className="theme-btn min-w-230 mt-10" style={{minWidth: "190px", backgroundColor: "#ff2641"}} onClick={() => {
                            props.declineDocument(props.type);
                        }}>
                        Refuser
                    </button>
                    
                </span>
            ) : (
                ''
            )}

    </div>
    )
}

function Wallet(props) {

    let [transactions, setTransactions] = useState([])

    let reload = () => {
        FasterApi.getWalletTransactions(props.driver.user_id).then(({data}) => {
            setTransactions(data)
        })
    }

    let { register, handleSubmit, watch, errors, formState, reset } = useForm({
        mode: 'all'
    });

    useEffect(() => {
        reload()
    }, [])

    return (

            <div class="table-wrapper s-table-content-wrapper profile-cont-sec" style={{padding: "0"}}>

                <div class="d-table full-width s-table-content">

                    <div class="d-table-row">

                        <div class="d-table-cell" style={{textAlign: "left", padding: "0 10px 0 10px"}}>
                            Solde: {
                                (() => {
                                    let wb = props.driver.wallet_balance || 0
                        
                                    if (wb === 0) {
                                        return <span> 0 CFA</span>
                                    } else {
                                        return <span style={{color: `${wb > 0 ? "green" : "red"}`}}> {wb > 0 ? "+" : "-"} {wb + " CFA"}</span>
                                    }
                                })()
                            }
                        </div>

                        <div class="d-table-cell" style={{textAlign: "right", padding: "0 10px 0 0"}}>

                            <Popup 
                                className="popup-box"
                                trigger={
                                    <button class="theme-btn min-w-230 mt-10" style={{minWidth: "190px", marginBottom: "10px"}}>Ajouter une transaction</button>
                                }     
                                modal
                                nested >
                                    { (close) => (
                                    <div class="sm-content-block sm-content-block-w1 lg-white-bg mt-0 align-center">
                                        <div class="close-popup popup-info1_close" onClick={close}><svg class="icon close-icon"><use href="/images/svg-sprite.svg#close-icon"></use></svg></div>
                                        <div class="popup-box-inner align-center">

                                            <form onSubmit={handleSubmit((data) => {
                                                
                                                let send = {
                                                    amount: data.amount, 
                                                    reason: data.reason
                                                }

                                                if (data.type === "debited") {
                                                    send.debited_user_wallet_id = props.driver.user_id
                                                } else if (data.type === "credited") {
                                                    send.credited_user_wallet_id = props.driver.user_id
                                                }

                                                FasterApi.createWalletTransactions(props.driver.user_id, send).then((res) => {
                                                    reload()
                                                    props.reload()
                                                    toast.success("🚀 Transaction réussite.")
                                                    close()
                                                    reset()
                                                })

                                            })}>

                                                <h3 class="align-center">Ajouter une transaction à {props.driver.User.lastname} {props.driver.User.firstname} </h3>

                                                <div class="reset-message">

                                                    <label> <input ref={register({ required: true })} type="radio" name="type" value="debited"  /> Débité </label>
                                                    <label><input ref={register({ required: true })} type="radio" name="type" value="credited"   /> Crédité </label> <br />  <br />                                           

                                                    <input ref={register({ required: true })} type="number" name="amount" placeholder="Montant en CFA" className="th-textbox" /><br /><br />    
                                                    <textarea ref={register({ required: true })} name="reason" placeholder="Raison de la transaction" className="th-textbox" ></textarea><br /><br /> 
                                                </div>

                                                <div class="w-50-inner">

                                                    <span onClick={close}><a href="#" class="popup-info1_close btn-inline-block">Annuler</a></span>

                                                    <input
                                                        type="submit" 
                                                        href="#" 
                                                        class="theme-btn theme-btn-gradient" 
                                                        value="Créer la transaction" 
                                                        style={{background: !formState.isValid && '#9396AD'}} 
                                                        disabled={!formState.isValid} 
                                                    />

                                                </div>
                                            </form>

                                        </div>

                                    </div> ) }
                            </Popup>
                                    
                        </div>
                       
                    </div>

                </div>
                
                <div class="d-table full-width s-table-content">

                    <div class="d-table-row table-header" >

                        <div class="d-table-cell"></div>

                        <div class="d-table-cell">
                            <span class="c-d-arrow" style={{ fontSize: 16 }}>ID <svg class="icon icon-arrow-down icon-white"><use href="images/svg-sprite.svg#d-arrow-black"></use></svg></span>
                        </div>

                        <div class="d-table-cell">
                            <span class="c-d-arrow" style={{ fontSize: 16 }}>Montant <svg class="icon icon-arrow-down icon-white"><use href="images/svg-sprite.svg#d-arrow-black"></use></svg></span>
                        </div>

                        <div class="d-table-cell">
                            <span class="c-d-arrow" style={{ fontSize: 16 }}>Raison <svg class="icon icon-arrow-down icon-white"><use href="images/svg-sprite.svg#d-arrow-black"></use></svg></span>
                        </div>


                        <div class="d-table-cell"></div>
                        
                    </div>
                    
                    {

                        transactions.map((el) => {
                            return (
                                <div class="d-table-row table-data-row">
                                    <div class="d-table-cell"></div>
                                    <div class="d-table-cell"> {el.id} </div>
                                    <div class="d-table-cell"> <span style={{color: `${el.credited_user_wallet_id === props.driver.user_id ? "green" : "red"}`}}>{el.credited_user_wallet_id === props.driver.user_id ? "+" : "-"} {el.amount + " CFA"}</span> </div>
                                    <div class="d-table-cell">{el.reason}</div>
                                    <div class="d-table-cell"></div>

                                </div>
                            )
                        })

                    }

                </div>

            </div>

    )

}