import React, { useEffect, useState, useRef, useCallback } from 'react'

var initialized = [];

export const useGoogleMapsApi = function (apiKey) {
    var _a = useState(), googleApi = _a[0], setGoogleApi = _a[1];
    useEffect(function () {
        if (window.google) {
            // if window.google object is already available just use it
            setGoogleApi(window.google);
            return;
        }
        var src = "https://maps.googleapis.com/maps/api/js?key=" + apiKey;
        var existingScript = initialized.find(function (el) { return el.src === src; });
        var onLoad = function () {
            setGoogleApi(window.google);
        };
        if (existingScript) {
            // if script tag was added by other element just check when it is loaded
            existingScript.addEventListener("load", onLoad);
            return;
        }
        var script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.defer = true;
        script.addEventListener("load", onLoad);
        document.head.appendChild(script);
        initialized.push(script);
        return function () {
            script.removeEventListener("load", onLoad);
            script.remove();
            initialized.splice(initialized.findIndex(function (el) { return el.src === src; }), 1);
        };
    }, [apiKey]);
    return googleApi;
}

export const useGoogleMaps = function (apiKey, options) {
    var google = useGoogleMapsApi(apiKey);
    var ref = useRef(null);
    var _a = useState(), map = _a[0], setMap = _a[1];

    useEffect(function () {
        if (!google || !ref || !ref.current) {
            return; 
        }
        setMap(new google.maps.Map(ref.current, options));
    }, [google, ref.current]);

    return { ref: ref, map: map, google: google };
}