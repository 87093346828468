import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Swal from 'sweetalert2'

import 'sweetalert2/dist/sweetalert2.min.css'

import FasterApi from '../Services/FasterApi'

import { useForm } from "react-hook-form";

import {
    Redirect, Link
} from "react-router-dom";

export default function CreatePhoneNumber(props) {

    let phoneregx = /^(\+33|0)[1-9](\d\d){4}$/

    let { register, handleSubmit, watch, errors, formState } = useForm({
        mode: 'all'
    });

    let [phoneNumberCreated, setPhoneNumberCreated] = useState(false)

    if (phoneNumberCreated) {
        return <Redirect to="/verifyPhoneNumber"></Redirect>
    }


    return (

        <div className="content-area-wrapper faster-logo-bg bg-logo-pos1">

            <div class="main-container">
                <div class="full-screen-height d-flex flex-column grid-bg-sec">
                    <div class="sm-content-block box-shadow1 lg-white-bg">
                        <div class="faster-logo adjust-logo-sm-con"><img src="images/faster-logo.svg" alt="Faster" /></div>
                        <h3 class="align-center">Ajoutez votre numéro de téléphone</h3>
                        <div class="form-wrapper">

                            <form onSubmit={handleSubmit((data) => {

                                console.log("okok")
                                FasterApi.createPhoneNumber({ phone_number: data.phone_number }).then((res) => {
                                    console.log("createPhoneNumber: ",res)
                                    if (res.status === 200) {
                                        toast.success("🚀 Création du numéro de téléphone réussite.")
                                        setPhoneNumberCreated(true)
                                    } else {
                                        toast.error("Une erreur s'est produite !")
                                    }
                                })
                            })}>

                                <div class="form-control">
                                    <label>Numéro de téléphone</label>
                                    <div class="">
                                        <input ref={register({ required: true })} type="tel" className="th-textbox" name="phone_number" placeholder="" />

                                    </div>
                                </div>



                                <input type="submit" className="theme-btn mt-40 d-block upper-case text-shadow1 box-shadow2" value="Valider" style={{ width: '100%', backgroundColor: !formState.isValid && '#9396AD', marginBottom: 20 }}
                                   // disabled={!formState.isValid}
                                />

                                {/* <Link class="forget-pass " to={'/login'} > */}
                                    <label onClick={() => {
                                    localStorage.clear()
                                    props.history.push('/login')
                                    }}>Revenir à la connexion</label>
                                {/* </Link> */}

                            </form>

                        </div>
                    </div>

                    <div class="connexion-path-wrapper">
                        <div class="circle-path-point"><span>&nbsp;</span></div>
                        <div class="circle-tooltip">
                            <div class="faster-icon tooltip-icon">
                                <span class="faster-logo-icon"><svg class="icon icon-faster-logo icon-white"><use href="/images/svg-sprite.svg#faster-logo"></use></svg></span>
                            </div>
                            <p>Le chauffeur qui vous accompagne dans tous vos déplacements.</p>
                        </div>
                        <div class="connexion-path"><img src="images/connexion-path1.png" alt="Path" /></div>
                    </div>

                </div>
            </div>
        </div>

    );

}
