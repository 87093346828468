import React, { useEffect, useState } from 'react'
import {
    Link,
    useLocation
} from 'react-router-dom'
import { toast } from 'react-toastify';
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useForm } from "react-hook-form";

import FasterApi from '../../Services/FasterApi'

import PrettyETHTable from '../../Components/PrettyETHTable'


import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import S3Picture from '../../Components/S3Picture'


export function List(props) {

    const location = useLocation();

    return (
        <PrettyETHTable
            title="Réclamations"
            dataLoader={FasterApi.getClaims}
            dataLoaderParams={props.match.params}

            columns={[
                {
                    title: 'Réf',
                    column: 'id',
                    filter: {
                        type: 'string:equal'
                    }
                },
                {
                    title: 'Réf course ',
                    value: ({ Trip }) => {
                        return Trip.id
                    }
                },
                {
                    title: 'Date',
                    column: 'created_at',
                    value: ({ created_at }) => moment(created_at).format('DD/MM/YYYY')
                },
                {
                    title: 'Client',
                    value: ({ Trip }) => {
                        return <Link className="user-p-link" to={'/admin/users/' + Trip.Passenger.id}>
                            <S3Picture className="user-profile-img" type="user-profil" file={Trip.Passenger.picture} />
                            {Trip.Passenger.firstname} {Trip.Passenger.lastname}
                        </Link>
                    }

                },

                

                {
                    title: 'Chauffeur',
                    value: ({ Trip }) => {
                        return <Link className="user-p-link" to={'/admin/users/' + Trip.Driver.id}>
                            <S3Picture className="user-profile-img" type="user-profil" file={Trip.Driver.picture} />
                            {Trip.Driver.firstname} {Trip.Driver.lastname}
                        </Link>
                    }

                },


                {
                    title: 'Statut',
                    column: 'status',
                    value: ({ status }) => { 
                        
                        if (status === 0) {
                            return <span class="color3" >En cours de traitement</span>
                        } else {
                            return <span class="color4" >Classé & Archivé</span>
                        }    
                    
                    
                    }
                },

                {
                    title: 'Actions',
                    value: ({ id }) => {
                        return <Link to={'/admin/claims/' + id}>
                            Voir
                        </Link>
                    }
                },


            ]}
        >

        </PrettyETHTable>
    )

}

export function Single(props) {

    const { register, handleSubmit, formState, errors } = useForm({
        mode: 'all'
    });


    const user = useSelector(state => state.user)

    let [claim, setClaim] = useState({})
    let [message, setMessage] = useState("")

    let reload = () => {


        FasterApi.getClaim(props.match.params).then(({ data }) => {
            console.log("claim: ", data)
            setClaim(data)
        })
    }

    let createClaimMessage = (message) => {

        FasterApi.createClaimMessage(claim.id, { message: message }).then((data) => {
            if (data.status === 201) {
                toast.success("Votre message a été envoyé avec succès.")
                reload()

            } else {
                toast.error("Une erreur s'est produite ! ")


            }
        })
    }



    useEffect(() => {
        reload()
    }, [])

    if (!claim.id) {
        return (<div></div>)
    }

    return (<div class="content-area-wrapper faster-logo-bg pb-20">

        <div class="main-content">
            <div class="page-title-sec pad-tb-1 space-between d-flex t-flex-start">
                <div class="page-sec-nav">
                    {/* <a href="#"><svg class="icon icon-arrow-back"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg>Retour</a> */}
                </div>
            </div>

            <div class="bg-white-sec mb-50">
                <div class="summary-row mlr-25-minus d-flex flex-wrap eq-he-cols space-between">
                    <div class="full-width-imp d-flex flex-wrap space-between">
                        <h3 class="theme-color pb-20 mb-0">Détail de la réclamation</h3>
                        <h3 class="color2 pb-20 mb-0">{claim.status === 0 ? "En traitement" : claim.status === 1 ? "Traité" : "Classé et Archivé"}</h3>
                    </div>
                </div>

                <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols">
                    <div class="summary-col"><label>Réf réclamation</label></div>
                    <div class="summary-col">
                        <p>{claim.id}</p>
                    </div>
                    <div class="summary-col">
                        <label>Réf course</label>
                    </div>
                    <div class="summary-col">
                        <p>{claim.trip_id}</p>
                    </div>
                </div>

                <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols">
                    <div class="summary-col"><label>Client</label></div>

          

                    <div class="summary-col">
                        <p>
                            <Link className="user-p-link theme-color fw-600" to={'/admin/drivers/' + claim.Trip.Passenger.id}>
                                <S3Picture className="user-profile-img" type="user-profil" file={claim.Trip.Passenger.picture} />{claim.Trip.Passenger.firstname + " " + claim.Trip.Passenger.lastname}
                            </Link>
                            
                            </p>
                    </div>


                    <div class="summary-col">
                        <label>Coordonnées <br />client</label>
                    </div>
                    <div class="summary-col"  style={{ maxWidth: '20%' }}>
                        <p>
                            <a href="mailto:johndoe@outlook.fr">{claim.Trip.Passenger.email}</a><br />
                            <a href="tel:+221651658110">{claim.Trip.Passenger.phone_number}</a>
                        </p>
                    </div>
                </div>

                <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols">
                    <div class="summary-col"><label>Trajet effectué</label></div>
                    <div class="summary-col loc-pointer-icon">
                        <div class="loc-pointer"><img src="/images/location-pointer.svg" alt="" /></div>
                        <label class="color-purple">Départ</label>
                        <p class="fs-16">{claim.Trip.address_from}</p>
                        <label class="color-purple mt-25 d-block">Arrivée</label>
                        <p class="fs-16">{claim.Trip.address_to}</p>
                    </div>
                    <div class="summary-col">
                        <label>Date et heure <br />de la course</label>
                        <label class="mt-25 d-block">Kilomètres et durée <br />de la course</label>
                    </div>
                    <div class="summary-col">
                        <p>{moment(claim.Trip.created_at).format('DD/MM/YYYY') + " à " + moment(claim.Trip.created_at).format('HH:mm')}</p>
                        <p style={{ "padding-top": "55px" }}>{(claim.Trip.distance / 1000).toFixed(0)} km - {(claim.Trip.duration / 60).toFixed(0)} min</p>
                    </div>
                </div>

                <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols">
                    <div class="summary-col"><label>Chauffeur</label></div>

                    <div class="summary-col">
                        <p>
                            <Link className="user-p-link theme-color fw-600" to={'/admin/drivers/' + claim.Trip.Driver.id}>
                                <S3Picture className="user-profile-img" type="user-profil" file={claim.Trip.Driver.picture} />{claim.Trip.Driver.firstname + " " + claim.Trip.Driver.lastname}
                            </Link>
                            
                            </p>
                    </div>


                    <div class="summary-col">
                        <label>Coordonnées <br />chauffeur</label>
                    </div>
                    <div class="summary-col"  style={{ maxWidth: '20%' }}>
                        <p>
                            <a href="mailto:konanjoe@outlook.fr">{claim.Trip.Driver.email}</a><br />
                            <a href="tel:+222793013920">{claim.Trip.Driver.phone_number}</a>
                        </p>
                    </div>
                </div>

                {/* <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols">
                    <div class="summary-col"><label>Gestionnaire</label></div>
                    <div class="summary-col">

                        <label class="color-purple d-block fw-600"><span class="user-icon-circle mr-10"><svg class="icon nav-icon nav-icon2"><use href="/images/svg-sprite.svg#nav-icon2"></use></svg></span>Non attribué</label>
                    </div>
                </div> */}

                <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols no-border">
                    <div class="summary-col"><label>Détail de la réclamation</label><span class="fw-400 fs-18 d-block">Réduire</span></div>
                    <div class="summary-col col-size-75-percent">
                        <p>{claim.message}</p>

                    </div>
                </div>
                {claim.ClaimMessages.map((el) => {
                    return <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols no-border">
                        <div class="summary-col"><label>{user.id === el.user_id ? "Votre réponse" : "Réponse " + el.User.firstname + " " + el.User.lastname}</label><span class="fw-400 fs-18 d-block">{moment(el.created_at).format('DD/MM/YYYY') + " à " + moment(el.created_at).format('HH:mm')}</span></div>
                        <div class="summary-col col-size-75-percent">
                            <p>{el.message}</p>

                        </div>
                    </div>
                })

                }

                { claim.status === 0 && <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols no-border">
                    <div class="summary-col"><label>Votre réponse</label><span class="fw-400 fs-18 d-block">Réduire</span></div>
                    <div class="summary-col col-size-75-percent">
                        <form onSubmit={handleSubmit((data, event) => {
                            console.log("data: ", data)
                            createClaimMessage(data.message)
                            event.target.reset()
                        })}>
                            <textarea
                                name="message"
                                ref={register({ required: true })}
                                class="th-textbox th-textarea"
                                placeholder="Votre message ici"


                            ></textarea>
                            {errors.message && <span>This field is required</span>}
                            <input type="submit" class="theme-btn mt-40"
                                value='Envoyer votre réponse'
                                disabled={!formState.isValid}
                            />
                        </form>

                        <button class="theme-btn mt-40" style={{background: 'var(--color-orange)'}}
                            onClick={() => {
                                console.log('yo ?')
                                FasterApi.closeClaim(claim.id).then((data) => {
                                    if (data.status === 200) {
                                        toast.success("La réclamation a été classée et archivée.")
                                        reload()
                                    } else {
                                        toast.error("Une erreur s'est produite ! ")
                                    }
                                })
                            }}
                        > Classée et archivée</button>
                    </div>
                </div> }

            </div>
        </div>

    </div>
    )

}