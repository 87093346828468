import { createStore, combineReducers } from 'redux'

const initialGlobalState = {
    user: {}
}

function globalReducer(state = initialGlobalState, action) {

    switch(action.type) {

        case 'SET_USER':
            return {...state, ...{ user: action.payload } }

        default: 
            return state

    }

}

const store = createStore(globalReducer)

export default store