import React, { Component } from 'react';

class S3Picture extends Component {

    constructor(props) {
        super(props)
        // this.state = {endpoint: "https://faster-space.fra1.digitaloceanspaces.com/"}
        this.state = { endpoint: "https://ezee-app-public-dev.s3.eu-central-1.amazonaws.com/" }
        
    }

    render() {
        return <img src={this.state.endpoint + this.props.type + "/" + this.props.file} alt={""} className={this.props.className} style={this.props.style} />
    }

}

export default S3Picture;