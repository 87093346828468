import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Swal from 'sweetalert2'

import 'sweetalert2/dist/sweetalert2.min.css'

import FasterApi from '../Services/FasterApi'

import { useForm } from "react-hook-form";

import {
    Redirect, Link
} from "react-router-dom";

export default function LoginScreen() {

    let emailregx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    let { register, handleSubmit, watch, errors, formState } = useForm({
        mode: 'all'
    });

    let [logged, setLogged] = useState(false)

    if (logged) {
        return <Redirect to="/"></Redirect>
    }

    return (

        <div className="content-area-wrapper faster-logo-bg bg-logo-pos1">

            <div class="main-container">
                <div class="full-screen-height d-flex flex-column grid-bg-sec">
                    <div class="sm-content-block box-shadow1 lg-white-bg">
                        <div class="faster-logo adjust-logo-sm-con"><img src="images/faster-logo.svg" alt="Faster" /></div>
                        <h3 class="align-center">Se connecter</h3>
                        <div class="form-wrapper">

                            <form onSubmit={handleSubmit((data) => {

                                console.log("okok")

                                FasterApi.auth({ email: data.email, password: data.password }).then((res) => {

                                    if (res.status === "OK") {
                                        toast.success("🚀 Connexion réussie.")
                                        setLogged(true)
                                        FasterApi.getMe()
                                    } else {
                                        toast.error("Vos identifiants sont invalides.")
                                    }

                                })

                            })}>
                                <div class="form-control">
                                    <label>Identifiant</label>
                                    <div class="pad-left-icon field-icon">
                                        <input ref={register({ required: true, pattern: emailregx })} type="text" className="th-textbox" name="email" placeholder="Votre email" />
                                        <div class="field-left-icon field-mid-icon"><svg class="icon user-icon"><use href="/images/svg-sprite.svg#user-icon"></use></svg></div>
                                    </div>
                                </div>
                                <div class="form-control">
                                    <label>Mot de passe</label>
                                    <div class="pad-right-icon pad-left-icon password-field field-icon">
                                        <input type="password" ref={register({ required: true })} className="th-textbox" name="password" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" />
                                        <div class="field-left-icon field-mid-icon"><svg class="icon lock-icon"><use href="/images/svg-sprite.svg#lock-icon"></use></svg></div>
                                    </div>
                                </div>
                                {/* <div class="forget-pass"><a href="#">J'ai oublié mon mot de passe</a></div> */}

                                <input type="submit" className="theme-btn mt-40 d-block upper-case text-shadow1 box-shadow2" value="Se connecter" style={{ width: '100%', marginBottom: 20, backgroundColor: !formState.isValid && '#9396AD' }} disabled={!formState.isValid} />

                                <Link class="forget-pass " to={'/register'} >
                                    <label>Inscrivez-vous</label>
                                </Link>

                            </form>

                        </div>
                    </div>

                    <div class="connexion-path-wrapper">
                        <div class="circle-path-point"><span>&nbsp;</span></div>
                        <div class="circle-tooltip">
                            <div class="faster-icon tooltip-icon">
                                <span class="faster-logo-icon"><svg class="icon icon-faster-logo icon-white"><use href="/images/svg-sprite.svg#faster-logo"></use></svg></span>
                            </div>
                            <p>Le chauffeur qui vous accompagne dans tous vos déplacements.</p>
                        </div>
                        <div class="connexion-path"><img src="images/connexion-path1.png" alt="Path" /></div>
                    </div>

                </div>
            </div>
        </div>

    );

}
