import React, { useEffect, useState } from 'react'
import './App.css';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useSelector } from 'react-redux'

import moment from 'moment'
import 'moment/locale/fr'
import fr from 'date-fns/locale/fr';

import * as AWS from 'aws-sdk'

import FasterApi from './Services/FasterApi'
import Login from './Screens/Login'
import Register from './Screens/Register'
import CreatePhoneNumber from "./Screens/CreatePhoneNumber";
import VerifyPhoneNumber from './Screens/VerifyPhoneNumber'

import * as Header from './Components/Header'

import * as ScreenAdminTransactions from './Screens/Admin/Transactions'
import * as ScreenAdminUsers from './Screens/Admin/Users'
import * as ScreenAdminDrivers from './Screens/Admin/Drivers'
import * as ScreenAdminClaims from './Screens/Admin/Claims'
import * as ScreenAdminTrips from './Screens/Admin/Trips'
import * as ScreenAdminBookings from './Screens/Admin/Bookings'

import * as ScreenDriverSummary from './Screens/Driver/Summary'
import * as ScreenDriverTrips from './Screens/Driver/Trips'
import * as ScreenDriverIncomes from './Screens/Driver/Incomes'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// AWS.config.update({
//     accessKeyId: 'W24N6ZJ7VOM4VWWV3E6V',
//     secretAccessKey: 'NOcFNswbU8EAwcNS5jHbVZT+JzD9XgR2MhvYxfyxd6M',
//     signatureVersion: 'v4',
//     region: 'eu-central-1'
// })

setDefaultLocale('fr');
registerLocale('fr', fr);
moment.locale('fr')

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (

        <Route {...rest} render={(props) => {
            if (FasterApi.isLogged()) {
                return <Component {...props} />
            } else {
                return <Redirect to="/login" />
            }

        }} />
    );
};

const AdminPrivateRoute = ({ component: Component, ...rest }) => {
    return (

        <Route {...rest} render={(props) => {

            if (FasterApi.isLogged()) {

                if (rest.user.role > 1) {
                    return <Component {...props} />
                } else {
                    return <Redirect to="/driver/summary" />
                }
                
            } else {
                return <Redirect to="/login" />
            }

        }} />
    );
};

function App() {

    let [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {

        FasterApi.getMe().then(() => {
            setTimeout(() => {
                setIsLoaded(true)
            }, 1000)
        })

    }, [])

    let user = useSelector((state) => state.user)

    if (!isLoaded) {
        return <div></div>
    }

    return (

        <Router>

            <ToastContainer
                newestOnTop={true}
            />

            <div>

                <Switch>

                    <Route exact path="/login" render={() => {

                        if (!FasterApi.isLogged()) {
                            return <Login></Login>
                        } else {

                            return <Redirect to="/redirect"></Redirect>
                        }

                    }}>


                    </Route>

                    <Route exact path="/register" component={Register}></Route>
                    
                    <Route exact path="/createPhoneNumber" component={CreatePhoneNumber}></Route>

                    <Route exact path="/verifyPhoneNumber" component={VerifyPhoneNumber}></Route>


                    {/* <PrivateRoute path='/partners/companies/create' component={ScreenPartnerCompany.Create} exact ></PrivateRoute> */}

                    {/* <PrivateRoute path='/admin/companies' exact>
                        <PrivateRoute component={Header.Big}></PrivateRoute>
                        <PrivateRoute path='/partners/companies' component={ScreenPartnerCompany.List} exact></PrivateRoute>
                    </PrivateRoute> */}

                    <AdminPrivateRoute user={user} path='/admin' >
                        <AdminPrivateRoute user={user} component={Header.Big}></AdminPrivateRoute>
                        <AdminPrivateRoute user={user} component={Header.Admin}></AdminPrivateRoute>

                        {/* <AdminPrivateRoute path='/admin/companies/:company_id' user={user} component={ScreenPartnerCompany.Single} exact></AdminPrivateRoute> */}

                        {/* Bookings */}
                        <AdminPrivateRoute path='/admin/transactions' user={user} component={ScreenAdminTransactions.List} exact ></AdminPrivateRoute>
                        <AdminPrivateRoute path='/admin/transactions/:transaction_id' user={user} component={ScreenAdminTransactions.Single} exact ></AdminPrivateRoute>

                        <AdminPrivateRoute path='/admin/users' user={user} component={ScreenAdminUsers.List} exact ></AdminPrivateRoute>
                        <AdminPrivateRoute path='/admin/users/:user_id(\d+)' user={user} component={ScreenAdminUsers.Single} exact ></AdminPrivateRoute>

                        <AdminPrivateRoute path='/admin/drivers' user={user} component={ScreenAdminDrivers.List} exact ></AdminPrivateRoute>
                        <AdminPrivateRoute path='/admin/drivers/:driver_id(\d+)' user={user} component={ScreenAdminDrivers.Single} exact ></AdminPrivateRoute>

                        <AdminPrivateRoute path='/admin/claims' user={user} component={ScreenAdminClaims.List} exact ></AdminPrivateRoute>
                        <AdminPrivateRoute path='/admin/claims/:claim_id(\d+)' user={user} component={ScreenAdminClaims.Single} exact ></AdminPrivateRoute>

                        <AdminPrivateRoute path='/admin/trips' user={user} component={ScreenAdminTrips.List} exact ></AdminPrivateRoute>
                        <AdminPrivateRoute path='/admin/trips/:trip_id(\d+)' user={user} component={ScreenAdminTrips.Single} exact ></AdminPrivateRoute>

                        <AdminPrivateRoute path='/admin/bookings' user={user} component={ScreenAdminBookings.List} exact ></AdminPrivateRoute>
                        <AdminPrivateRoute path='/admin/bookings/:booking_id(\d+)' user={user} component={ScreenAdminBookings.Single} exact ></AdminPrivateRoute>


                        {/* Stadiums */}
                        {/* <PrivateRoute path='/admin/companies/:company_id/stadiums' component={ScreenPartnerCompanyStadiums.List} exact ></PrivateRoute>
                        <PrivateRoute path="/admin/companies/:company_id/stadiums/:stadium_id(\d+)" component={ScreenPartnerCompanyStadiums.Single} ></PrivateRoute>
                        <PrivateRoute path='/admin/companies/:company_id/stadiums/create' component={ScreenPartnerCompanyStadiums.Create} exact ></PrivateRoute>

                        {/* Users */}
                        {/* <PrivateRoute path='/admin/companies/:company_id/users' component={ScreenPartnerCompanyUsers.List} exact ></PrivateRoute> */}

                        {/* Planning */}
                        {/* <PrivateRoute path='/admin/companies/:company_id/planning' component={ScreenPartnerCompanyPlanning.List} exact ></PrivateRoute> */}

                        {/* Settings */}
                        {/* <PrivateRoute path='/admin/companies/:company_id/settings' component={ScreenPartnerCompanySettings.Wrapper} exact></PrivateRoute>  */}

                        {/* <PrivateRoute path={/^(?!.*logsin).*$/} currentuser={{}} component={Header} /> */}
                    </AdminPrivateRoute>

                    <PrivateRoute path='/driver' >

                        <PrivateRoute component={Header.Big}></PrivateRoute>
                        <PrivateRoute component={Header.Driver}></PrivateRoute>

                        <PrivateRoute path='/driver/summary' component={ScreenDriverSummary.Single} exact ></PrivateRoute>
                        <PrivateRoute path='/driver/trips' component={ScreenDriverTrips.List} exact ></PrivateRoute>
                        <PrivateRoute path='/driver/trips/:trip_id(\d+)' component={ScreenDriverTrips.Single} exact ></PrivateRoute>
                        <PrivateRoute path='/driver/incomes' component={ScreenDriverIncomes.Single} exact ></PrivateRoute>


                    </PrivateRoute>

                    {/* This is ELSE case, when any route doesn't meet requirements */}
                    <Route
                        render={() => {

                            if (FasterApi.isLogged()) {

                                if (user && user.role > 1) {
                                    // If Admin we redirect to admin section
                                    return <Redirect to="/admin/transactions"></Redirect>
                                } else {
                                    // If logged and not admin so it's a driver, we redirect to driver section

                                    // if (user && user.phone_number) {
                                        return <Redirect to="/driver/summary"></Redirect>
                                    // } else {
                                    //     return  <Redirect to="/createPhoneNumber"></Redirect>
                                    // }

                                }

                            } else {
                                return <Redirect to="/login" />
                            }

                        }}
                    />

                </Switch>
            </div>


        </Router>

    );
}

export default App;
