import React, { useEffect, useState } from 'react'
import {
    Link,
    useLocation
} from 'react-router-dom'

import moment from 'moment'


import FasterApi from '../../Services/FasterApi'

import PrettyETHTable from '../../Components/PrettyETHTable'


import 'reactjs-popup/dist/index.css';

import S3Picture from '../../Components/S3Picture'

import { useGoogleMaps } from '../../Services/GoogleMap'

import gmapstyle from '../../gmapstyle'


const decodePolyline = require('decode-google-map-polyline');


export function List(props) {

    const location = useLocation();

    return (
        <PrettyETHTable
            title="Trajets"
            dataLoader={FasterApi.getTrips}
            dataLoaderParams={props.match.params}

            columns={[
                {
                    title: 'Réf',
                    column: 'id',
                    filter: {
                        type: 'string:equal'
                    }
                },

                {
                    title: 'Client / Chauffeur',
                    value: ({ Passenger, Driver }) => {

                        let users = [
                            <Link className="user-p-link" to={location.pathname + '/' + Passenger.id}>
                                <S3Picture className="user-profile-img" type="user-profil" file={Passenger.picture} />
                                {Passenger.firstname} {Passenger.lastname}
                            </Link>,
                            <br />,
                            <br />
                        ]

                        if (Driver) {
                            users.push(
                                <Link className="user-p-link" to={location.pathname + '/' + Driver.id}>
                                    <S3Picture className="user-profile-img" type="user-profil" file={Driver.picture} />
                                    {Driver.firstname} {Driver.lastname}
                                </Link>)
                        } else {
                            users.push("Aucun chauffeur attribué")
                        }

                        return users
                    }

                },

                {
                    title: 'Adresses',
                    // column: "amount_paid",
                    value: ({ address_from, address_to }) => [
                        address_from,
                        <br />,
                        address_to
                    ]
                },

                {
                    title: 'Date',
                    column: "created_at",
                    value: ({ created_at }) => moment(created_at).format('LLLL')
                },

                {
                    title: 'Durée / Distance',
                    column: "duration",
                    value: ({ duration, distance }) => (duration / 60).toFixed(0) + "min" + " / " + (distance / 1000).toFixed(0) + "km"
                },

                {
                    title: 'Prix',
                    column: "amount_to_pay",
                    value: ({ amount_to_pay }) => amount_to_pay + " CFA"
                },

                {
                    title: 'Statut',
                    value: ({ status }) => {

                        switch (status) {
                            case 0:
                                return <span>Course créée sans <br />demande de chauffeur</span>
                            case 1:
                                return "En cours de recherche de chauffeur"
                            case 11:
                                return <span>Un chauffeur a été trouvé, <br /> en attente de <br /> validation de paiement</span>
                            case 2:
                                return <span>Paiement validé <br /> par le client</span>
                            case 3:
                                return <span>Le chauffeur est arrivé <br /> à la position du client</span>
                            case 31:
                                return "Le chauffeur a prit en charge le client"
                            case 4:
                                return "La course est finie"
                            case 19:
                                return <span>Course annulée car <br /> aucun chauffeur <br /> n'a été trouvé</span>
                            case 9:
                                return <span>Course annulée car <br /> aucun chauffeur <br /> n'a été trouvé</span>
                            case 99:
                                return <span>Le passager a annulé <br /> la course</span>
                            case 999:
                                return <span>Le chauffeur a annulé <br /> la course</span>
                            case 9999:
                                return <span>Annulation du client <br /> lors de la recherche</span>
                        }

                    }
                },
                {
                    title: '',
                    value: ({ id }) => {
                        return <Link to={'/admin/trips/' + id}>
                            Voir
                        </Link>
                    }
                }

            ]}
        >

        </PrettyETHTable>
    )


}

export function Single(props) {

    let [trip, setTrip] = useState({})
    let [center, setCenter] = useState({ lat: 59.95, lng: 30.33 })
    let [point_from, setPoint_from] = useState({ lat: 59.95, lng: 30.33 })
    let [point_to, setPoint_to] = useState({ lat: 59.95, lng: 30.33 })

    const { ref, map, google } = useGoogleMaps(
        "AIzaSyA7tucuOJJjLvqefVHw_3I48xkeVa-vB_o",
        {
            center: center,
            zoom: 10,
            styles: gmapstyle
        },
    );

    if (map) {

        new google.maps.Marker({ position: point_from, map });
        new google.maps.Marker({ position: point_to, map });
        new google.maps.Polyline({
            path: decodePolyline(trip.polyline),
            geodesic: true,
            strokeColor: "#01C2AD",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            map: map
        });

    }

    let reload = () => {

        FasterApi.getTrip(props.match.params).then(({ data }) => {
            console.log("getTrip: ", data)
            setTrip(data)
            if (data) {
                setCenter({ lat: data.point_from.coordinates[0], lng: data.point_from.coordinates[1] })
                setPoint_from({ lat: data.point_from.coordinates[0], lng: data.point_from.coordinates[1] })
                setPoint_to({ lat: data.point_to.coordinates[0], lng: data.point_to.coordinates[1] })
            }

        })
    }


    useEffect(() => {
        reload()
    }, [])

    if (!trip.id) {
        return (<div></div>)
    }

    return (<div class="content-area-wrapper faster-logo-bg pb-20">

        <div class="main-content">
            <div class="page-title-sec pad-tb-1 space-between d-flex t-flex-start">
                <div class="page-sec-nav">
                    {/* <a href="#"><svg class="icon icon-arrow-back"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg>Retour</a> */}
                </div>
            </div>

            <div class="bg-white-sec mb-50">
                <div class="summary-row mlr-25-minus d-flex flex-wrap eq-he-cols space-between">
                    <div class="full-width-imp d-flex flex-wrap space-between">
                        <h3 class="theme-color pb-20 mb-0">Détail de la course</h3>
                        <h3 class="color3 pb-20 mb-0">

                            {
                                (() => {
                                    switch (trip.status) {
                                        case 0:
                                            return <span>Course créée sans demande de chauffeur</span>
                                        case 1:
                                            return "En cours de recherche de chauffeur"
                                        case 11:
                                            return <span>Un chauffeur a été trouvé, en attente de validation de paiement</span>
                                        case 2:
                                            return <span>Paiement validé par le client</span>
                                        case 3:
                                            return <span>Le chauffeur est arrivé à la position du client</span>
                                        case 31:
                                            return "Le chauffeur a prit en charge le client"
                                        case 4:
                                            return "La course est finie"
                                        case 19:
                                            return <span>Course annulée car aucun chauffeur n'a été trouvé</span>
                                        case 9:
                                            return <span>Course annulée car aucun chauffeur n'a été trouvé</span>
                                        case 99:
                                            return <span>Le passager a annulé la course</span>
                                        case 999:
                                            return <span>Le chauffeur a annulé la course</span>
                                        case 9999:
                                            return <span>Annulation du client lors de la recherche</span>
                                    }
                                })()
                            }

                        </h3>
                    </div>
                </div>

                <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols">
                    <div class="summary-col"><label>Réf course</label></div>
                    <div class="summary-col">
                        <p>{trip.id}</p>
                    </div>
                    <div class="summary-col">
                        <label>Tarif</label>
                    </div>
                    <div class="summary-col">
                        <p>{trip.amount_to_pay}</p>
                    </div>
                </div>

                <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols">
                    <div class="summary-col"><label>Client</label></div>
                        <div class="summary-col">
                            <p>
                                <Link className="user-p-link theme-color fw-600" to={'/admin/users/' + trip.Passenger.id}>
                                    <S3Picture className="user-profile-img" type="user-profil" file={trip.Passenger.picture} />{trip.Passenger.firstname + " " + trip.Passenger.lastname}
                                </Link>
                            </p>
                        </div>
                    
                    <div class="summary-col">
                        <label>Coordonnées <br />client</label>
                    </div>
                    <div class="summary-col" style={{ maxWidth: '20%' }}>
                        <p>
                            <a>{trip.Passenger.email}</a><br />
                            <a>{trip.Passenger.phone_number}</a>
                        </p>
                    </div>
                </div>

                <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols">
                    <div class="summary-col"><label>Trajet effectué</label></div>
                    <div class="summary-col loc-pointer-icon">
                        <div class="loc-pointer"><img src="/images/location-pointer.svg" alt="" /></div>
                        <label class="color-purple">Départ</label>
                        <p class="fs-16">{trip.address_from}</p>
                        <label class="color-purple mt-25 d-block">Arrivée</label>
                        <p class="fs-16">{trip.address_to}</p>
                    </div>
                    <div class="summary-col">
                        <label>Date et heure <br />de la course</label>
                        <label class="mt-25 d-block">Kilomètres et durée <br />de la course</label>
                    </div>
                    <div class="summary-col">
                        <p>{moment(trip.created_at).format('DD/MM/YYYY') + " à " + moment(trip.created_at).format('HH:mm')}</p>
                        <p style={{ "padding-top": "55px" }}>{(trip.distance / 1000).toFixed(0)} km - {(trip.duration / 60).toFixed(0)} min</p>
                    </div>
                </div>

                {trip.Driver && <div class="summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols">
                    <div class="summary-col"><label>Chauffeur</label></div>
                        <div class="summary-col">
                            <p>
                                <Link className="user-p-link theme-color fw-600" to={'/admin/drivers/' + trip.Driver.id}>
                                    <S3Picture className="user-profile-img" type="user-profil" file={trip.Driver.picture} />{trip.Driver.firstname + " " + trip.Driver.lastname}
                                </Link>
                                
                                </p>
                        </div>
                    
                    <div class="summary-col">
                        <label>Coordonnées <br />chauffeur</label>
                    </div>
                    <div class="summary-col" style={{ maxWidth: '20%' }}>
                        <p>
                            {trip.Driver.email}<br />
                            {trip.Driver.phone_number}
                        </p>
                    </div>
                </div>}
                
                {trip.Claims.length > 0 && <Link style={{ marginTop: 20, }} className="user-p-link" to={'/admin/claims/' + trip.Claims[0].id}>
                    <div class="summary-col">
                        <p><a class=" theme-color " href="#" style={{ textDecorationLine: 'underline' }}>Voir la réclamation</a></p>
                    </div>
                </Link>}

                <div ref={ref} style={{ width: '100%', height: 500, marginTop: 20 }} />
            </div>
        </div>

    </div>
    )

}